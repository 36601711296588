.myDonation {
  margin-top: 32px;
  padding: 18px 0px 26px 0px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  .mySwiper {
    height: auto;
    max-height: 520px;

    ul {
      padding: 0px 45px;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0px;
        border-bottom: 1px solid #F3F3F3;

        @include tablet_768 {
          display: block;
        }

        .left_section {
          display: flex;
          gap: 16px;
          align-items: center;

          @include tablet_768 {
            justify-content: space-between;
          }

          .desc {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: 0.01em;
            color: #000000;
          }

          .subscribe {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #1BA92A;

            @include tablet_768 {
              flex: 1;
            }
          }
        }

        .right_section {
          display: flex;
          align-items: center;
          gap: 16px;
          max-height: 60px;

          @include tablet_768 {
            justify-content: flex-end;
          }

          @include mobile_500 {
            width: 100%;
            justify-content: space-between;
          }

          .info {
            display: flex;
            gap: 16px;
          }

          .amount {
            font-family: $fontFamily;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #1BA92A;
            display: flex;
            align-items: center;
          }

          .card {
            display: flex;
            align-items: center;
            gap: 8px;

            img {
              object-fit: contain;
              width: 40px;
              height: 40px;
            }
          }

          .date_place {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #A8D7ED;
          }
        }
      }
    }
  }
}

.user_reports {
  width: 100%;
  margin-top: 48px;

  .report__headline {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    display: flex;
    align-items: flex-end;
    color: #1E1E1E;
    padding-bottom: 24px;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 24px;

    @include tablet_600 {
      font-size: 20px;
      line-height: 24px;
    }
    @include mobile_500 {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .report__header__filter {
    display: flex;
    align-items: center;
    gap: 21px;
    margin-bottom: 24px;
    position: relative;

    @include tablet_600 {
      display: grid;
      gap: 12px;
    }

    .openedSelector {
      position: absolute;
      top: 70px;
      display: grid;
      padding: 12px;
      width: 263px;
      background: #FFFFFF;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
      border-radius: 8px;

      .header_selector {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: 8px;

        .selectedYear {
          display: flex;
          align-items: flex-start;
          gap: 4px;
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: #1B1B1B;

          span {
            align-self: flex-end;
          }
        }

        .year_map {
          margin-right: 10px;
          position: absolute;
          top: 30px;
          left: 0px;
          width: 100px;
          padding-right: 4px;
          background: #FFFFFF;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
          border-radius: 8px;

          .scroll_thump {
            margin-top: 10px;
            margin-bottom: 10px;
            // display: flex;
            // flex-direction: column;
            align-items: flex-start;
            max-height: 90px;
            overflow-y: auto;

            button {
              width: 80px;
              background: none;
              text-align: left;
              padding: 4px 10px;
              border: none;
              outline: none;
              font-family: $fontFamily;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
              color: #1B1B1B;
              cursor: pointer;
            }

            &::-webkit-scrollbar {
              width: 2px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: #888;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }
          }
        }

        .year_action {
          display: flex;
          gap: 12px;

          button {
            background: none;
            outline: none;
            border: none;
            cursor: pointer;
          }
        }
      }

      .month_section {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        flex-wrap: wrap;
        margin-top: 12px;
        row-gap: 0px;
        column-gap: 12px;

        button {
          width: 100%;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          border: none;
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: #1B1B1B;
          cursor: pointer;
        }

        button.active {
          color: #fff;
          background: #2697DC;
          border-radius: 16px;
        }
      }
    }

    .filter_desc {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #939393;

      @include tablet_600 {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .filter_button {
      padding: 12px 20px;
      border: 2px solid #E8E8E8;
      border-radius: 8px;
      background: #fff;
      outline: none;
      display: flex;
      align-items: center;
      gap: 16px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #2697DC;
      cursor: pointer;
      max-width: 240px;

      .right_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;

        svg {
          path {
            stroke: #2697DC;
          }
        }
      }
    }

    .filter_active {
      border: 2px solid #2697DC
    }
  }
}


.reports_list_section {
  margin-bottom: 80px;
}