.submenu{
  display: none;
  min-width: 211px;
  min-height: 132px;
  height: auto;
  position: absolute;
  top:15px;
  right:left;
  width: 100px;
  z-index: 2;
  &.active_menu{
    display: block;
  }

  &__links{
    padding-top: 6px;
    padding-bottom: 6px !important;
    background:#fff;
    margin-top: 20px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    &__item{
      background-color:white;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color:#1e1e1e;
      padding: 8px 16px;
      transition: all 0.3s;
      &:hover{
        background-color:#EDF9FF;
        color:#2196F3;
      }

      // &:last-child{
      //   margin-bottom: 6px;
      // }
    }
  }
}
// .active_menu{

// }