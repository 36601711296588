.media {
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      color: #000;
    }

    &__button-group {
      display: flex;
      gap: 6px;
      @include mobile_500 {
        display: none;
      }
      button {
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;
      }
    }
  }

  &__body {
    margin-top: 45px;
    margin-bottom: 70px;
    @include mobile_500 {
      width: 110%;
    }
    .swiper {
      width: 100%;
      height: 214px;
      max-height: 214px;
    }
    .swiper-slide {
      width: 100%;
      height: auto;
    }
    .swiper-slide img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    .swiper-slide video {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    .swiper-pagination {
      display: none;
    }
  }
}

.modal {
  font-size: 12px;
  max-width: 1008px;
  width: 100%;
  height: 588px;
  background: #fff;
  border-radius: 6px !important;
  @include desktop_1290 {
    max-width: 900px !important;
    width: 100%;
    margin: 0 auto;
  }

  @include tablet_900 {
    max-width: 700px !important;
    width: 100%;
    margin: 0 auto;
  }
  @include tablet_768 {
    max-width: 600px !important;
  }
  @include tablet_650 {
    max-width: 550px !important;
  }
  @include mobile_500 {
    max-width: 100vw !important;
    height: 300px;
    background-color: transparent;
  }
  @include mobile_330 {
    // max-width: 280px !important;
    height: 300px;
  }

  .content {
    height: 100%;

    .swiper-slide {
      width: 100%;
      @include mobile_500 {
        background-color: transparent;
        padding: 0px;
      }
    }
    .mediaSwiper {
      height: 100%;
      width: 100%;
      border-radius: 6px;

      .swiper-button-prev::after {
        margin-left: 80px;
        content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjQ4IiBoZWlnaHQ9IjQ4IiByeD0iMjQiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDQ4IDApIiBmaWxsPSIjRURGOUZGIiBmaWxsLW9wYWNpdHk9IjAuNSIvPgo8cGF0aCBkPSJNMzEuNSAyNUMzMi4wNTIzIDI1IDMyLjUgMjQuNTUyMyAzMi41IDI0QzMyLjUgMjMuNDQ3NyAzMi4wNTIzIDIzIDMxLjUgMjNWMjVaTTE1LjI5MjkgMjMuMjkyOUMxNC45MDI0IDIzLjY4MzQgMTQuOTAyNCAyNC4zMTY2IDE1LjI5MjkgMjQuNzA3MUwyMS42NTY5IDMxLjA3MTFDMjIuMDQ3NCAzMS40NjE2IDIyLjY4MDUgMzEuNDYxNiAyMy4wNzExIDMxLjA3MTFDMjMuNDYxNiAzMC42ODA1IDIzLjQ2MTYgMzAuMDQ3NCAyMy4wNzExIDI5LjY1NjlMMTcuNDE0MiAyNEwyMy4wNzExIDE4LjM0MzFDMjMuNDYxNiAxNy45NTI2IDIzLjQ2MTYgMTcuMzE5NSAyMy4wNzExIDE2LjkyODlDMjIuNjgwNSAxNi41Mzg0IDIyLjA0NzQgMTYuNTM4NCAyMS42NTY5IDE2LjkyODlMMTUuMjkyOSAyMy4yOTI5Wk0zMS41IDIzSDE2VjI1SDMxLjVWMjNaIiBmaWxsPSJibGFjayIvPgo8L3N2Zz4K);
        @include mobile_500 {
          display: none;
        }
      }
      .swiper-button-next::after {
        margin-right: 80px;
        content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjQ4IiBoZWlnaHQ9IjQ4IiByeD0iMjQiIGZpbGw9IiNFREY5RkYiIGZpbGwtb3BhY2l0eT0iMC41Ii8+CjxwYXRoIGQ9Ik0xNi41IDI1QzE1Ljk0NzcgMjUgMTUuNSAyNC41NTIzIDE1LjUgMjRDMTUuNSAyMy40NDc3IDE1Ljk0NzcgMjMgMTYuNSAyM1YyNVpNMzIuNzA3MSAyMy4yOTI5QzMzLjA5NzYgMjMuNjgzNCAzMy4wOTc2IDI0LjMxNjYgMzIuNzA3MSAyNC43MDcxTDI2LjM0MzEgMzEuMDcxMUMyNS45NTI2IDMxLjQ2MTYgMjUuMzE5NSAzMS40NjE2IDI0LjkyODkgMzEuMDcxMUMyNC41Mzg0IDMwLjY4MDUgMjQuNTM4NCAzMC4wNDc0IDI0LjkyODkgMjkuNjU2OUwzMC41ODU4IDI0TDI0LjkyODkgMTguMzQzMUMyNC41Mzg0IDE3Ljk1MjYgMjQuNTM4NCAxNy4zMTk1IDI0LjkyODkgMTYuOTI4OUMyNS4zMTk1IDE2LjUzODQgMjUuOTUyNiAxNi41Mzg0IDI2LjM0MzEgMTYuOTI4OUwzMi43MDcxIDIzLjI5MjlaTTE2LjUgMjNIMzJWMjVIMTYuNVYyM1oiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=);
        @include mobile_500 {
          display: none;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      padding: 24px;
      object-fit: contain;
      border-radius: 50px;
      @include mobile_500 {
        border-radius: 0px !important;
        margin-top: 36px;
        padding: 0px;
      }
    }

    video {
      width: 100%;
      height: 100%;
      padding: 24px;
      object-fit: contain;
      border-radius: 50px;
      @include mobile_500 {
        border-radius: 0px !important;
        margin-top: 36px;
        padding: 0px;
      }
    }
  }
}

.modal > .close {
  cursor: pointer;
  border: none;
  outline: none;
  position: absolute;
  display: block;
  right: 36px;
  top: 36px;
  z-index: 100;
  width: 48px;
  height: 48px;
  background: rgba(237, 249, 255, 0.5);
  border-radius: 100px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  @include mobile_500 {
    top: -30px;
    right: 16px;
  }
}

.region_width {
  @include mobile_500 {
    width: 110%;
  }
}
