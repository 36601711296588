.regions{
  padding-top: 56px;
  &__title{
    color:#2697DC;
    text-align: center;
    margin-bottom: 25px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 110%;
    text-align: center;
    color:#2697DC;
    @include mobile_500{
      font-size: 22px;
    }
  }
  &__description{
    width: 100%;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color:#000;
    text-align: center;
  }

  &__all{
    margin: 0 auto;
    margin-top: 60px;
    display: grid;
    gap:25px 24px;
    grid-template-columns:repeat(5, 1fr);
    width: 90%;
    @include desktop_1290 {
      max-width: 1000px;
      grid-template-columns:repeat(4, 1fr);
    }
    @include tablet_1024 {
      max-width: 90%;
      width: 95%;
      grid-template-columns:repeat(3, 1fr);
    }
    @include tablet_768 {
      grid-template-columns:repeat(2, 1fr);
    }
    @include mobile_500 {
      grid-template-columns:repeat(2, 1fr);
    }
  }
}


.single__region{
  position: relative;
  margin: 0 auto;
  width: 200px;
  height:200px;
  background-color: #EDF9FF;
  border-radius: 100px;
  display: flex;
  align-items:center;
  justify-content: center;
  @include mobile_500 {
    width: 160px;
    height: 160px;
  }
  @include mobile_400 {
    width: 120px;
    height: 120px;
  }

  .single__region__layer{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items:center;
    visibility: hidden;
    border-radius: 100px;
    transition: all 0.5s ease-in;

    &__title{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 110%;
      text-align: center;
      visibility: hidden;
      color:#fff;
      transition: all 0.6s ease-in-out;
      word-break: break-word;
      @include mobile_400 {
        font-size: 16px;
      }
    }
  }
  &:hover .single__region__layer{
    background:#2697DC;
    visibility: visible;
  }
 
  &:hover .single__region__layer__title{
    visibility: visible;
  }

  &__info__region{
    width: 96px;
    height:133px;
    @include mobile_400 {
      width: 60px;
      height: 100px;
    }

    &__img{
      width: 100%;
      height:100%;
      object-fit: contain;
    }
  }
}


.region__news{
  &__header{
    display: flex;
    align-items:center;
    gap:24px;

    .new_title{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      color:#000;
    }
    .news-link{
      background: #EDF9FF;
      border-radius: 100px;
      padding: 12px 24px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color:#2697DC;
    }
  }

  &__body{
    margin-top: 32px;
    margin-bottom: 32px;
    display: grid;
    gap:24px;
    grid-template-columns:repeat(4, 1fr);
  }
}

.region__all{
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  display: grid;
  gap:24px;
  grid-template-columns:repeat(6, 1fr);

  &__card{
    position: relative;
    cursor:pointer;
    width:200px;
    height:200px;
    background:#EDF9FF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items:center;
    img{
      object-fit: contain;
    }
    .card__layer{
      display: none;
      background:transparent;
      transition: all 0.5s ease-in-out;

      h1{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 110%;
        text-align: center;
        color: #FFFFFF;
      }
    }
    &:hover .card__layer{
      position: absolute;
      top:0;
      left:0;
      background:#2697DC;
      border-radius: 50%;
      width: 100%;
      height:100%;
      display: flex;
      align-items:center;
      justify-content: center;
    }
  }
}



.news_flex{
  justify-content: space-between;
}
.button__group__slide{
  align-self: flex-end;
  display: flex;
  gap:6px;
  align-items:center;

  @include mobile_500{
    display: none  ;
  }

  &__btn{
    border: none;
    outline: none;
    background:transparent;
    cursor: pointer;
  }
}

.region_flex{
  display: flex;
  align-items:center;
  justify-content: space-between;
}

.region__head_left{
  display: flex;
  align-items:center;
  gap:24px;
}
.region_Swiper{
  margin-top: 36px;
  height: auto;
  img{
    width: 100%;
    height:100%;
    object-fit: contain;
  }
}