.user_profile{
  max-width: 880px;
  width: 68%;
  margin: 96px auto;

  @include tablet_900{
    width: 100%;
  }
  @include tablet_768{
    width: 70%;
  }
  @include mobile_500{
    width: 100%;
    margin-top: 24px;
  }

  &__head{
    width: 100%;
    display: flex;
    align-items: center;
    gap:24px;
    padding-bottom: 32px;
    border-bottom: 1px solid #D7ECF6;

    &__left{
      padding: 23px;
      background-color:#2697DC;
      border-radius: 8px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 130%;
      color: #FFFFFF;
      text-transform: uppercase;
      @include mobile_500{
        padding: 16px;
      }
      @include mobile_330{
        padding: 12px;
        font-size:24px;
      }
    }
    &__right{
      display: grid;
      gap:0px;

      span{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #5A89AA;
      }
      div{
        display: flex;
        align-items:flex-start;
        gap:12px;
        span{
          padding-top: 5px;
          cursor: pointer;
          @include mobile_500{
            padding-top: 0px;
            font-size: 16px;
          }
        }
        h4{
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 120%;
          color: #1E1E1E;
          @include mobile_500{
            font-size: 24px;
          }
        }
      }
    }
  }

}


.user-form{
  margin-top: 40px;
  @include mobile_500{
    margin-top: 0px;
  }
  .header{
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 47px;
    color: #1E1E1E;
    display: block;
    @include mobile_500{
      display: none;
    }
  }
  .header::first-letter{
    text-transform: capitalize;
  }
}


.form_group_st{
  margin-top: 16px;
  margin-bottom: 32px;
  display: grid;
  gap:24px;
  grid-template-columns: repeat(2,1fr);
  @include tablet_768{
    grid-template-columns: repeat(1,1fr);
    gap:0px;
  }

  .group_flex{
    display: flex;
    justify-content: space-between;
  }
  .custom_label{
    margin-bottom: 4px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  input{
    width: 100%;
    border: 2px solid #F0F0F0;
    border-radius: 8px;
    padding: 13px 20px;
    outline:none;

    &::placeholder{
      font-family:$fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #1E1E1E;
    }
  }
  .error_input{
    border:2px solid #ff0000;
  }
  .error{
    margin-top: 12px;
    color:red;
    display: flex;
    align-items:center;
    gap:10px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #FF0000;
  }
}
.radio-group{
  display: flex;
  gap:19px;

  .radio_check{
    display: flex;
    align-items:center;
    gap:10px;

    label{
      display: flex;
      gap:10px;
    }
    input[type='radio']{
      display: none;
    }
    input[type="radio"] +label::before{
      content:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIxIiB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHJ4PSI3LjUiIHN0cm9rZT0iI0U4RThFOCIvPgo8L3N2Zz4K);
    }
    input[type="radio"]:checked+label::before{
      content:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOCIgY3k9IjguNSIgcj0iNSIgZmlsbD0iIzI2OTdEQyIvPgo8cmVjdCB4PSIwLjUiIHk9IjEiIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgcng9IjcuNSIgc3Ryb2tlPSIjMjY5N0RDIi8+Cjwvc3ZnPgo=);
    }
  }
}

.custom_phone-box{
  display: flex;
  justify-content: space-between;
  align-items:flex-start;
  gap:12px;
  position: relative;

  .operator_list{
    position: absolute;
    z-index: 5;
    display: none;
    top:40px;
    left:0;
  }
  .active_box{
    display: block;
    width: 100px;
    ul{
      margin-top: 15px;
      background:#fff;
      border: 2px solid #F0F0F0;
      border-radius: 8px;
      li{
        padding: 6px 20px;
        display: flex;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        cursor:pointer;
      }
    }
  }
  button{
    width: 100px;
    display: flex;
    gap:25px;
    justify-content: center;
    align-items:center;
    padding: 11px 20px;
    background:none;
    border: 2px solid #F0F0F0;
    border-radius: 8px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    cursor:pointer;
  }

  input{
    width: 100%;
  }
}


.name_edit{
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom:0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.9);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content{
    max-width: 480px;
    width: 70%;
    background: #FFFFFF;
    border-radius: 6px;
    @include mobile_500{
      width: 100%;
    }

    // header
    &__head{
      display: flex;
      justify-content: space-between;
      align-items:center;
      padding: 16px 16px 16px 24px;
      border-bottom: 1px solid #F6F5F7;

      h6{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 46px;
        letter-spacing: -0.025em;
        color: #1E1E1E;
      }

      button{
        cursor: pointer;
        outline: none;
        background:transparent;
        border:none
      }
    }

    // body
    &__body{
      padding:32px 24px;

      &__forms{
        &__input-group{
          display: grid;
          gap:4px;

          label{
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #202020;
          }
          input{
            border: 2px solid #F0F0F0;
            border-radius: 8px;
            padding: 12px 20px;
            outline: none;
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #1E1E1E;
          }
        }
      }

      p{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }
      .button_group{
        display: flex;
        align-items:center;
        justify-content: flex-end;
        margin-top: 32px;

        button{
          width: 100%;
          cursor: pointer;
          outline: none;
          border:none;
          background:transparent;
          padding: 12px 24px;
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.02em;
          background: #2697DC;
          border-radius: 8px;
          color: #FFFFFF;
        }
      }
    }
  }
}



.mobile_button{
  @include mobile_500{
    width: 100% !important;
    button{
      width: 100% !important;
    }
  }
}