@mixin flex($align-item, $justify-content) {
  display: flex;
  align-items: $align-item;
  justify-content: $justify-content;
}

@mixin mobile {
  @media only screen and (max-width: $mobile-width) {
      @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet-width) {
      @content;
  }
}

@mixin desktop_1400{
  @media only screen and (max-width: $destop-1400-width) {
    @content;
  }
}

@mixin desktop_1380{
  @media only screen and (max-width: $destop-1380-width) {
    @content;
  }
}
@mixin desktop_1290{
  @media only screen and (max-width: $destop-1290-width) {
    @content;
  }
}
@mixin desktop_1190{
  @media only screen and (max-width: $destop-1190-width) {
    @content;
  }
}
@mixin desktop_1090{
  @media only screen and (max-width: $destop-1090-width) {
    @content;
  }
}
@mixin desktop_1060{
  @media only screen and (max-width: $destop-1060-width) {
    @content;
  }
}
@mixin tablet_1024{
  @media only screen and (max-width: $tablet-1024-width) {
    @content;
  }
}
@mixin tablet_900{
  @media only screen and (max-width: $tablet-900-width) {
    @content;
  }
}
@mixin tablet_830{
  @media only screen and (max-width: $tablet-830-width) {
    @content;
  }
}
@mixin tablet_768{
  @media only screen and (max-width: $tablet-768-width) {
    @content;
  }
}
@mixin tablet_720{
  @media only screen and (max-width: $tablet-720-width) {
    @content;
  }
}
@mixin tablet_700{
  @media only screen and (max-width: $tablet-700-width) {
    @content;
  }
}
@mixin tablet_650{
  @media only screen and (max-width: $tablet-650-width) {
    @content;
  }
}
@mixin tablet_600{
  @media only screen and (max-width: $tablet-600-width) {
    @content;
  }
}
@mixin mobile_500{
  @media only screen and (max-width: $mobile-500-width) {
    @content;
  }
}
@mixin mobile_400{
  @media only screen and (max-width: $mobile-400-width) {
    @content;
  }
}
@mixin mobile_330{
  @media only screen and (max-width: $mobile-330-width) {
    @content;
  }
}



