.search_head{
  margin-top: 56px;
  text-align: center;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 59px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #1E1E1E;

  .search_keyword{
    font-weight: 700;
    text-transform: capitalize;
  }
}

.search_result{
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap:16px;

  .result_title{
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    color: #1E1E1E;
  }
  .result_number{
    font-family: $fontFamily;
    margin-top: 3px;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 120%;
    color: #1E1E1E;
    opacity: 0.5;
  }
}

.search_notFound{
  margin-top: 80px;
  margin-bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items:center;

  .search_error{
    margin-top: 32px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.025em;
    color: #1E1E1E;

    .search_keyword{
      font-weight: 700;
      text-transform: capitalize;
    }
  }
}

.news_pagination{
  margin-bottom: 60px;
  margin-top: 60px;
}