.reports {
  margin-top: 56px;

  @include mobile_500 {
    margin-top: 32px;
  }

  &__header {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    letter-spacing: -0.025em;
    color: #1e1e1e;

    @include mobile_500 {
      font-size: 24px;
      line-height: 120%;
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 25px;

    // background-color: red;
    @include tablet_1024 {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
    }
  }
}

// left section
.report_left {
  width: 56%;
  // height: 200px;
  display: grid;
  gap: 24px;

  @include desktop_1400 {
    width: 55%;
    gap: 16px;
  }

  @include desktop_1380 {
    width: 54%;
  }

  @include desktop_1190 {
    width: 50%;
  }

  @include tablet_1024 {
    width: 100%;
    display: block;
  }
}

// donate_section
.donate__section {
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  // header start
  &__header {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid #f6f5f7;

    @include mobile_500 {
      flex-direction: column;
      gap: 5px;
    }

    &__left {
      display: flex;
      gap: 8px;
      align-items: center;

      h4 {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-top: -5px;
      }
    }

    &__right {
      display: flex;
      gap: 8px;
      align-items: center;

      h4 {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-top: -5px;
      }
    }
  }

  // header end
}

// right section

.report_right {
  width: 40%;
  // min-height: 940px;
  height: auto;
  max-height: 943px !important;
  overflow-y: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  padding-bottom: 100px;

  @include tablet_1024 {
    width: 100%;
  }
  @include mobile_500 {
    // max-height: 500px;
    padding-bottom: 20px;
  }

  &__header {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    display: flex;
    align-items: flex-start;
    gap: 8px;
    padding: 24px;
    border-bottom: 1px solid #f6f5f7;

    &__top {
      background: #515151;
      position: absolute;
      bottom: -3px;
      left: 50%;
      transform: translateX(-20%);
      border-radius: 4px;
      padding: 6px 12px;
      color: #fff;
      // margin-bottom: -2px;
    }

    &__triangle {
      margin-bottom: -6px;
      position: absolute;
      right: -10px;
      bottom: -4px;
      width: 10px;
      height: 10px;
      border-top: 10px solid #515151;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-radius: 50px;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;

    @include mobile_500 {
      height: 550px;
    }
  }

  .swiper-slide {
    padding: 20px;
    height: auto;
  }

  .swiper-scrollbar.swiper-scrollbar-vertical,
  .swiper-vertical > .swiper-scrollbar {
    right: 10px;
  }

  .live__donate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    padding: 14px 12px;

    @include mobile_330 {
      display: grid;
    }

    &:last-child {
      border: none;
    }

    &__left {
      display: flex;
      align-items: center;
      gap: 16px;

      @include mobile_330 {
        display: flex;
        width: 100%;
      }

      &__desc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: center;
        &__name {
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.02em;
          color: #000000;
          margin-bottom: 0px;
        }

        &__content {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.01em;
          color: #000000;
        }
      }
    }

    &__right {
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      gap: 0;
      justify-content: center;
      align-items: flex-end;

      @include mobile_330 {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: flex-start;
        gap: 10px;
      }

      span {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #a8d7ed;
      }
    }
  }
}

.not_donate {
  padding: 24px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
}
