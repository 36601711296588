
.mobile_sidebar{
  display: none;
  @include mobile_500{
  display: block;
  position: fixed;
  bottom: 0;
  z-index: 20;
  .first_side{
    height: 44px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items:center;
    border-top-left-radius:20px;
    border-top-right-radius:20px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
  }
  .mobileSidebar_open{
    transform: rotate(-180deg);
    transition: all 0.5s ease-in-out;
  }
  .mobileSidebar_close{
    transform: rotate(0deg);
    transition: all 0.5s ease-in-out;
  }
  // .second-side_open{
  //   display: block;
  //   height: 240px;
  //   transition: all 0.5s ease-in-out;
  // }
  // .second-side_close{
  //   display: grid;
  //   height: 0px;
  //   transition:all 0.5s ease-in-out;
  // }
  .second_side.open{
    grid-template-rows: 1fr;
  }
  .second_side{
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    // height:240px;
    width: 100%;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 400ms ease-in-out;
    .content{
      overflow: hidden;
    }
    ul{
      display: grid;
      grid-template-rows: 0fr;
      gap:10px;
      margin-bottom: 10px;
      li{
        display: flex;
        align-items:center;
        background:#fff;
        padding: 10px 12px;
        border-radius: 8px;
        gap:16px;
        .sidebar_description{
          display: flex;
          gap:0px;
          flex-direction: column;
          align-items:flex-start;
          justify-content: center;
          span{
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: 0.02em;
            color: #000000;
          }
          p{
            margin-top: 0px;
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            // display: flex;
            // align-items: center;
            letter-spacing: 0.01em;
            color: #000000;
             overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* number of lines to show */
                    line-clamp: 1; 
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
  }
  
}