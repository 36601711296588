.cancel {
  &__title {
    margin-top: 32px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 110%;
    color: #ffffff;
    text-align: left;
    max-width: 1004px;
    margin: 32px auto;
    display: block;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &__description {
    width: 90%;
    max-width: 1004px;
    margin: 32px auto;
    min-height: 70vh;
    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    padding: 60px;
    word-wrap: break-word;

    p {
      margin-bottom: 32px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #000;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.cancel_cover {
  position: absolute !important;
  top: 120px;
  width: 100%;
  height: 400px;
  object-fit: cover;
  z-index: -1;
  @include mobile_500 {
    top: 70px;
  }
}

.top_cover {
  margin-top: 20vh;
  z-index: 5;
}

.new__cancel {
  height: auto;

  .cov {
    position: relative;
    height: 400px;
  }

  &__cover {
    width: 100%;
    height: 400px;
    object-fit: cover;
    z-index: -1;
    position: absolute;
    top: 0;
  }

  &__description {
    max-width: 1004px;
    width: 90%;
    margin: 0 auto;
    margin-top: -30vh;
    z-index: 1;

    h1 {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 56px;
      line-height: 110%;
      color: #ffffff;
      margin-bottom: 32px;
    }

    &__body {
      background: #ffffff !important;
      position: relative;
      min-height: 60vh;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
      border-radius: 8px;
      padding: 60px;
      word-wrap: break-word;

      &__button {
        text-align: center;
        margin-top: 32px;
      }

      .radio-group {
        display: flex;
        flex-direction: column;
        gap: 19px;
      }

      h2 {
        text-align: center;
        font-size: 22px;
        margin-bottom: 42px;
      }

      p {
        margin-bottom: 8px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #000;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.cancel_mobile {
  position: relative;

  &__desc {
    margin-top: 24px;
    word-wrap: break-word;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
  }
  &__cover {
    position: relative;
    height: 200px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &__title {
      max-width: 228px;
      position: absolute;
      bottom: 30px;
      left: 5%;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 110%;
      color: #ffffff;
    }
  }
}

.otherAnswer {
  width: 100%;
  margin-top: 16px;
  input {
    margin-top: 4px;
    display: block;
    width: 100%;
    border: 2px solid #f0f0f0;
    border-radius: 8px;
    padding: 14px 16px;
    background-color: transparent;
    outline: none;
  }
}

.radio-group-cancel {
  display: flex;
  flex-direction: column;
  gap: 19px;

  .radio_check_cancel {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      display: flex;
      gap: 10px;
      font-size: 16px;
    }
    input[type="radio"] {
      display: none;
    }
    input[type="radio"] + label::before {
      font-size: 14px;
      content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIxIiB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHJ4PSI3LjUiIHN0cm9rZT0iI0U4RThFOCIvPgo8L3N2Zz4K);
    }
    input[type="radio"]:checked + label::before {
      content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOCIgY3k9IjguNSIgcj0iNSIgZmlsbD0iIzI2OTdEQyIvPgo8cmVjdCB4PSIwLjUiIHk9IjEiIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgcng9IjcuNSIgc3Ryb2tlPSIjMjY5N0RDIi8+Cjwvc3ZnPgo=);
    }
  }
}
