$main-bg: #fff;
$main-color: #2697DC;
$danger-bg:#FF4141;
$primary-light:#EDF9FF;
$primary-bg-light:#F1F8FB;

$txt-main-color: #000;
$txt-second-color: #8d8d8d;
$txt-white: #fff;
$txt-primary:#2697DC;

$btn-main-bg: $main-color;
$btn-main-color: #fff;

$header-height: 170px;
$header-tablet-height: 70px;
$header-mobile-height: 40px;
$header-shrink-height: 70px;

$fontFamily:'HelveticaNowDisplay';

$lt_02: 0.02em;


$box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

$colors: (
    "blue": #4267b2,
    "orange": #fbb96b,
    "pink": #fe7e73,
    "white": #fff,
    "black": #000,
    "main": $main-color,
    "red": #ff0000,
);

$mobile-width: 600px;
$tablet-width: 1024px;
$destop-1400-width:1410px;
$destop-1380-width:1380px;
$destop-1290-width:1290px;
$destop-1190-width:1190px;
$destop-1090-width:1090px;
$destop-1060-width:1060px;

// tablet
$tablet-1024-width:1024px;
$tablet-900-width:900px;
$tablet-830-width:830px;
$tablet-768-width:768px;
$tablet-720-width:720px;
$tablet-700-width:700px;
$tablet-650-width:650px;
$tablet-600-width:600px;


$mobile-500-width:500px;
$mobile-400-width:400px;
$mobile-330-width:330px;

$numbers: (0, 1, 2, 3, 4, 5, 6, 7, 8);