.donated_regions{
  // background:#fff;
  padding: 12px 24px;
  margin-bottom: 20px;
  display: grid;
  gap:12px;

  .donated_region__title{
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 8px;

    @include mobile_330{
      font-size: 12px;
    }
  }
  .donated_region__body{
    display: flex;
    align-items:center;
    gap:24px;

    .progress{
      padding: 8px 16px;
      min-width: 120px;
      background:#2697DC;
      display: flex;
      justify-content: flex-end;
      color:#fff;
      background: #2697DC;
      border-radius: 8px;

      span{
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.01em;

        @include mobile_500{
          font-size: 12px;
        }
      }
    }
    .regions_logo{
      width: 32px;
      height: 40px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}