.mobile_menu_hide {
  transform: translateX(200%);
  transition: transform 0.5s ease-in-out;
}

.mobile_menu_show {
  transform: translateX(0%);
  transition: transform 0.5s ease-in-out;
}

.mobile_menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 110;
  width: 50%;
  max-width: 512px;
  height: 100vh;
  // min-height: 110vh;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  padding: 24px 60px;
  overflow-y: auto;
  transition: all 0.5 ease-in-out;

  @include tablet_768 {
    width: 60%;
  }

  @include tablet_700 {
    width: 70%;
  }

  @include tablet_650 {
    width: 90%;
  }

  @include mobile_500 {
    width: 100%;
    padding: 16px 24px;
  }

  .mobile_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .mobile__head__left {
      display: flex;
      align-items: center;
      gap: 32px;
    }

    button {
      outline: none;
      border: none;
      background: transparent;
    }

    .mobile__head__right {
      button svg {
        width: 15px;
        height: 15px;

        path {
          stroke-width: 3;
        }
      }
    }

    .mobile_lang {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #000000;
      text-transform: uppercase;
    }

    .cabinet__btn {
      min-width: 160px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: right;
      letter-spacing: 0.02em;
      color: #2697DC;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      padding: 12px 24px;
      background: #EDF9FF;
      border-radius: 8px;
    }
  }
}


.donate_mobile_btn {
  padding: 12px;
  display: block;
  background: #34a853;
  border-radius: 8px;
  text-align: center;
  margin-top: 12px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.mobile_body {
  margin-top: 56px;
}

.mobile_accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.active_accordion {
  p {
    color: #2697DC;
  }

  svg {
    path {
      stroke: #2697DC;
    }
  }
}

.p_accordion {
  p {
    color: #000 !important;
  }

  svg {
    path {
      stroke: #000;
    }
  }
}

.mobile__accordion__link {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
}

.mobile_bottom {
  margin-bottom: 0px;
}

.accordion_open {
  transform: rotate(-180deg);
  transition: all 0.5s ease-in-out;
}

.accordion_close {
  transform: rotate(0deg);
  transition: all 0.5s ease-in-out;
}

.mobile__link {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;

  &:hover {
    color: #2697DC !important;
  }
}


.mobile_log_out {
  border: none;
  outline: none;
  background: transparent;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #FF4141;
}

.mobile_cabinet_button {
  min-width: 134px;
  background: #EDF9FF !important;
  display: flex;
  align-items: center;
  padding: 6.5px 6px !important;
  border-radius: 8px;
  gap: 12px;
  outline: none;
  border: none;

  span {
    &:nth-child(1) {
      padding: 8px;
      background: #2697DC;
      border-radius: 4px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 12.7273px;
      line-height: 130%;
      /* identical to box height, or 17px */
      color: #FFFFFF;
    }

    &:nth-child(2) {
      width: 100%;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #2697DC;
      text-align: left;
    }
  }
}



.m__accordion {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 300ms ease-in-out;
}

.m__accordion.open {
  display: grid;
  grid-template-rows: 1fr;
}

.m__accordion_content {
  overflow: hidden;
  display: grid;
  padding-top: 18px;
}

.m_accordion_link {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;

  &:last-of-type {
    margin-bottom: 18px;
  }

  &:hover {
    color: #2697DC;
  }
}