.soon{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 80px;
  &__header{
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    letter-spacing: -0.025em;
    color: #1E1E1E;
    margin-bottom: 27px;
  }
  span{
    // margin-top: -100px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 89.0163px;
    line-height: 131px;
    letter-spacing: -0.025em;
    color: #2697DC;
    padding: 22px 40px;
    background: #EDF9FF;
    border-radius: 222.541px;
  }
}