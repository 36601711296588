@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-ExtBdIta.woff2') format('woff2'),
        url('HelveticaNowDisplay-ExtBdIta.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-Bold.woff2') format('woff2'),
        url('HelveticaNowDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-MedIta.woff2') format('woff2'),
        url('HelveticaNowDisplay-MedIta.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay ExtraBlack';
    src: url('HelveticaNowDisplay-ExtBlk.woff2') format('woff2'),
        url('HelveticaNowDisplay-ExtBlk.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-Black.woff2') format('woff2'),
        url('HelveticaNowDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay Hairline';
    src: url('HelveticaNowDisplay-HairlineI.woff2') format('woff2'),
        url('HelveticaNowDisplay-HairlineI.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay ExtraBlack';
    src: url('HelveticaNowDisplay-ExtBlkIta.woff2') format('woff2'),
        url('HelveticaNowDisplay-ExtBlkIta.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay Hairline';
    src: url('HelveticaNowDisplay-Hairline.woff2') format('woff2'),
        url('HelveticaNowDisplay-Hairline.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-BoldIta.woff2') format('woff2'),
        url('HelveticaNowDisplay-BoldIta.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-BlackIta.woff2') format('woff2'),
        url('HelveticaNowDisplay-BlackIta.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-ExtLt.woff2') format('woff2'),
        url('HelveticaNowDisplay-ExtLt.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-ExtLtIta.woff2') format('woff2'),
        url('HelveticaNowDisplay-ExtLtIta.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-LightIta.woff2') format('woff2'),
        url('HelveticaNowDisplay-LightIta.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-ExtraBold.woff2') format('woff2'),
        url('HelveticaNowDisplay-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-Light.woff2') format('woff2'),
        url('HelveticaNowDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-Thin.woff2') format('woff2'),
        url('HelveticaNowDisplay-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-Regular.woff2') format('woff2'),
        url('HelveticaNowDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-RegIta.woff2') format('woff2'),
        url('HelveticaNowDisplay-RegIta.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-ThinIta.woff2') format('woff2'),
        url('HelveticaNowDisplay-ThinIta.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-Medium.woff2') format('woff2'),
        url('HelveticaNowDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNowDisplay';
    src: url('HelveticaNowDisplay-Bold.woff2') format('woff2'),
        url('HelveticaNowDisplay-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

