.auth__modal {
  position: fixed;
  z-index: 110;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.89);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto !important;
  @include tablet_650 {
    padding: 0px;
    overflow-y: scroll !important;
  }

  &__content {
    // margin-top: 100px;
    max-width: 540px;
    width: 70%;
    height: 80vh;
    max-height: 600px;
    background-color: #ffffff;
    overflow-y: auto;
    border-radius: 6px;
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(24, 115, 199, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(38, 151, 220, 0.7);
      outline: 1px solid slategrey;
    }
    @include tablet_650 {
      max-width: 100%;
      min-height: 100vh !important;
      height: 100vh;
      max-height: 100vh;
      width: 100%;
      overflow-y: scroll;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #f6f5f7;
      padding: 12px 24px;
      .modal_logo {
        width: 120px;
        height: 51px;
        object-fit: contain;
      }
      .modal_close {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }

    &__main {
      padding: 24px;

      &__buttons {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
        padding: 5px;
        background-color: #f4f4f4;
        border-radius: 8px;

        button {
          padding: 13px 50px;
          background-color: transparent;
          border: none;
          outline: none;
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #202020;
          cursor: pointer;
          @include mobile_500 {
            padding: 13px 10px;
          }
        }
        .active {
          background-color: #ffffff;
          border-radius: 8px;
        }
      }
    }
  }
}

.auth_section {
  padding-top: 10px;
  @include tablet_650 {
    overflow-y: scroll;
  }
}
.login_input {
  width: 100%;
  margin-top: 16px;

  label {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #202020;
  }
  input {
    margin-top: 4px;
    display: block;
    width: 100%;
    border: 2px solid #f0f0f0;
    border-radius: 8px;
    padding: 14px 16px;
    background-color: transparent;
    outline: none;
  }

  .error_input {
    border: 2px solid #ff0000;
  }
  .error_message {
    margin-top: 5px;
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff0000;
  }
}
.passport_error_message {
  margin-bottom: 60px;
  @include tablet_768 {
    margin-bottom: 80px;
  }
  @include tablet_650 {
    margin-bottom: 40px;
  }
}
.phone_section {
  display: flex;
  margin-top: 6px;
  gap: 8px;
}
.passport_section {
  display: flex;
  align-items: flex-end;
  margin-top: 6px;
  gap: 8px;
}
.p-inputtext {
  border: 2px solid #f0f0f0 !important;
  border-radius: 8px;
  padding: 14px 16px !important;
  width: 100%;
  &:enabled:focus {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    border: 2px solid #f0f0f0 !important;
  }
  &:enabled:hover {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
    border: 2px solid #f0f0f0 !important;
  }
}

#phone_operator {
  border: 2px solid #f0f0f0;
  border-radius: 8px;
  padding: 14px 16px;
  outline: none;
}

#passport_series {
  border: 2px solid #f0f0f0;
  border-radius: 8px;
  padding: 12px 16px;
  outline: none;
}

.error_phone {
  border: 2px solid red !important;
}
.auth_button {
  display: block;
  width: 100%;
  cursor: pointer;
  margin-top: 32px;
  background: #2697dc;
  border-radius: 8px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  padding: 12px;
  border: none;
  outline: none;
  &:disabled {
    opacity: 0.7;
  }
  @include tablet_650 {
    // margin-top: auto;
  }
}

.button_forget {
  background: transparent;
  border: none;
  outline: none;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #2697dc;
  display: block;
  margin: 20px auto;
  cursor: pointer;
}

.auth_save {
  padding-top: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  label {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #091e42;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;
    cursor: pointer;

    @include tablet_650 {
      justify-content: flex-start;
    }

    span {
      color: #2697dc;
    }
  }
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + label::before {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMSIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiByeD0iMiIgc3Ryb2tlPSIjRThFOEU4IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==);
  }
  input[type="checkbox"]:checked + label::before {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMSIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiByeD0iMiIgZmlsbD0iIzI2OTdEQyIgc3Ryb2tlPSIjMjY5N0RDIiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02Ljc0MTI4IDguOTk4ODRDNi42MDk1IDguODYyMzkgNi40NTE4NyA4Ljc1MzU2IDYuMjc3NTcgOC42Nzg2OUM2LjEwMzI4IDguNjAzODIgNS45MTU4MyA4LjU2NDQxIDUuNzI2MTQgOC41NjI3NkM1LjUzNjQ2IDguNTYxMTEgNS4zNDgzNCA4LjU5NzI2IDUuMTcyNzggOC42NjkwOUM0Ljk5NzIxIDguNzQwOTIgNC44Mzc3MSA4Ljg0NyA0LjcwMzU4IDguOTgxMTNDNC41Njk0NCA5LjExNTI2IDQuNDYzMzcgOS4yNzQ3NiA0LjM5MTU0IDkuNDUwMzNDNC4zMTk3MSA5LjYyNTkgNC4yODM1NiA5LjgxNDAxIDQuMjg1MjEgMTAuMDAzN0M0LjI4Njg2IDEwLjE5MzQgNC4zMjYyNyAxMC4zODA4IDQuNDAxMTQgMTAuNTU1MUM0LjQ3NjAxIDEwLjcyOTQgNC41ODQ4NCAxMC44ODcxIDQuNzIxMjggMTEuMDE4OEw3LjU3ODQzIDEzLjg3NkM3Ljg0NjMyIDE0LjE0MzggOC4yMDk2MiAxNC4yOTQyIDguNTg4NDIgMTQuMjk0MkM4Ljk2NzIzIDE0LjI5NDIgOS4zMzA1MyAxNC4xNDM4IDkuNTk4NDIgMTMuODc2TDE1LjMxMjcgOC4xNjE2OUMxNS40NDkyIDguMDI5OTEgMTUuNTU4IDcuODcyMjggMTUuNjMyOSA3LjY5Nzk5QzE1LjcwNzcgNy41MjM2OSAxNS43NDcxIDcuMzM2MjQgMTUuNzQ4OCA3LjE0NjU1QzE1Ljc1MDQgNi45NTY4NyAxNS43MTQzIDYuNzY4NzUgMTUuNjQyNSA2LjU5MzE5QzE1LjU3MDYgNi40MTc2MiAxNS40NjQ1IDYuMjU4MTIgMTUuMzMwNCA2LjEyMzk5QzE1LjE5NjMgNS45ODk4NSAxNS4wMzY4IDUuODgzNzggMTQuODYxMiA1LjgxMTk1QzE0LjY4NTYgNS43NDAxMiAxNC40OTc1IDUuNzAzOTcgMTQuMzA3OSA1LjcwNTYyQzE0LjExODIgNS43MDcyNyAxMy45MzA3IDUuNzQ2NjggMTMuNzU2NCA1LjgyMTU1QzEzLjU4MjEgNS44OTY0MiAxMy40MjQ1IDYuMDA1MjUgMTMuMjkyNyA2LjE0MTY5TDguNTg4NDIgMTAuODQ2TDYuNzQxMjggOC45OTg4NFoiIGZpbGw9IiNGQUZCRkMiLz4KPC9zdmc+Cg==);
  }
}

.register {
  @include tablet_650 {
    overflow-x: hidden;
  }
  .register_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @include tablet_650 {
      grid-template-columns: repeat(1, 1fr);
      gap: 0px;
    }
  }
}

.phone_label {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #202020;
}

.passport_label {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #202020;
}

.phone_div {
  margin-top: 16px;
}

.passport_div {
  margin-top: 16px;
}

.password_parent {
  position: relative;
  .eye_icon {
    position: absolute;
    top: 40px;
    right: 15px;
    cursor: pointer;
  }
}

.register_radioButton {
  // margin-top: 10px;
  display: flex;
  gap: 20px;
  label {
    margin-top: 10px;
    display: flex;
    align-items: center !important;
    gap: 6px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
  }
  input[type="radio"] {
    display: none !important;
  }
  input[type="radio"] + label::before {
    margin-top: 6px;
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIxIiB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHJ4PSI3LjUiIHN0cm9rZT0iI0U4RThFOCIvPgo8L3N2Zz4K);
  }
  input[type="radio"]:checked + label::before {
    margin-top: 6px;
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iOCIgY3k9IjguNSIgcj0iNSIgZmlsbD0iIzI2OTdEQyIvPgo8cmVjdCB4PSIwLjUiIHk9IjEiIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgcng9IjcuNSIgc3Ryb2tlPSIjMjY5N0RDIi8+Cjwvc3ZnPgo=);
  }
}
.g_b_grid {
  display: grid;
  align-items: flex-start;
  gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  @include tablet_650 {
    grid-template-columns: repeat(1, 1fr);
    gap: 0px;
  }
}

.gender_section {
  padding-top: 16px;
}
.gender_Label {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
}

.error_alert {
  background: #ff4141;
  padding: 10px;
  margin-bottom: 2px;
  border-radius: 6px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.birth {
  margin-top: 16px;

  label {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
  .birth_select {
    margin-top: 4px;
    width: 70%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    @include tablet_650 {
      width: 100%;
    }

    select {
      padding: 13px 20px;
      border: 2px solid #f0f0f0;
      border-radius: 8px;
      outline: none;
      @include tablet_650 {
        padding: 12px 4px;
      }
    }
  }
}

.register_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  margin-bottom: 200px;

  .register__email__title {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    letter-spacing: -0.025em;
    color: #1e1e1e;
  }

  .register__email__desc {
    margin-top: 16px;
    margin-bottom: 24px;
    p {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 162.5%;
      text-align: center;
      letter-spacing: -0.03em;
      color: #000000;
    }
  }
}

.forget_password_content {
  max-width: 462px;
  width: 70%;
  background: #ffffff;
  border-radius: 6px;
  @include tablet_650 {
    width: 100%;
    height: 100vh;
  }

  .forget_password__head {
    padding: 22px 16px 22px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f6f5f7;

    .forget_password__title {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      letter-spacing: -0.025em;
      color: #1e1e1e;
    }

    .forget_close {
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
    }
  }
  .forget_password_body {
    padding: 24px;
    .forget_password_form {
      width: 100%;
      display: grid;
      gap: 4px;
      @include tablet_650 {
        min-height: 100vh;
        // height: auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }

      label {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #202020;
      }
      .forget_email_input {
        width: 100%;
        padding: 14px 16px;
        border: 2px solid #f0f0f0;
        border-radius: 8px;
        outline: none !important;
      }
      .error_email {
        border: 2px solid #ff0000;
      }
      .forget_button {
        margin-top: 28px;
        @include tablet_650 {
          // align-self:flex-end;
          height: auto;
          min-height: 55vh;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        }

        button {
          padding: 12px;
          width: 100%;
          border: none;
          outline: none;
          background: #2697dc;
          border-radius: 8px;
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.02em;
          color: #ffffff;

          &:disabled {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

.error_input_aa {
  border: 2px solid #ff0000 !important;
}

.error_message_aa {
  padding-top: 4px;
  color: #ff4141 !important;
  display: flex;
  gap: 4px;
  font-family: $fontFamily;
  font-size: 14px;
}

.auto_login {
  @include tablet_650 {
    min-height: 65vh;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    div {
      width: 100%;
    }
    .auth_button {
      align-self: flex-end;
      justify-self: flex-end;
    }
  }
}

.register_submit {
  @include mobile_500 {
    margin-bottom: 60px;
  }
}
