.publication {
  padding-top: 56px;

  @include tablet_768 {
    padding-top: 32px;
  }

  &__header {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    letter-spacing: -0.025em;
    color: #1E1E1E;

    @include mobile_500 {
      font-size: 24px;
      line-height: 120%;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 32px;
    margin-bottom: 32px;
  }

}

.single__publication {
  padding: 24px;
  width: 100%;
  max-width: 872px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border-radius: 10px;

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;

    @include mobile_500 {
      flex-wrap: wrap;
    }

    h5 {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #1E1E1E;
      max-width: 461px;
      word-wrap: break-word;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
      -webkit-line-clamp: 2;
    }

    span {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #7C9EAE;

      @include mobile_500 {
        display: block;
        // width: 100%;
      }
    }
  }

  &__description {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include tablet_700 {
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
    }

    .editor {
      width: 100%;
      max-width: 461px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #7C9EAE;
      word-wrap: break-word;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
      -webkit-line-clamp: 3;

      @include mobile_500 {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 10px;
      }
    }

    a {
      padding: 12px 24px;
      background: #2697DC;
      border-radius: 8px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;

      @include tablet_700 {
        margin-top: 10px;
        width: 100%;
        text-align: center;
      }
    }

    button {
      cursor: pointer;
      outline: none;
      border: none;
      padding: 12px 24px;
      background: #2697DC;
      border-radius: 8px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;

      @include tablet_700 {
        text-align: center;
      }
    }
  }
}

.downLoadContent {
  transform: translateX(-200vh);
  // transform: translateX(0px);
  position: absolute;
  top: 0;
  overflow-x: auto;
}

.pageDownload {
  padding: 30px 40px;
  // width: 100vw;
  min-width: 720px;
  width: 100vw;
  max-width: 720px;
  display: grid;
  overflow-x: auto;

  .page_header {
    display: flex;
    align-items: center;
    gap: 32px;

    img {
      height: 64px;
      object-fit: fill;
    }

    h1 {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 120%;
      color: #1E1E1E;
    }
  }

  .page_month {
    padding: 32px 0px;
    display: flex;
    justify-content: space-between;

    p {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 120%;
      color: #1E1E1E;
    }
  }

  .report__data {
    border-top: 2px solid #F6F5F7;
  }

  .report_all {
    display: grid;
    align-items: center;
    grid-template-columns: 1.5fr 2fr 1fr;
    background: #FFFFFF;
    border-bottom: 2px solid #F6F5F7;
    padding: 24px 12px;
  }

  .one {
    display: flex;
    gap: 16px;
    align-items: center;

    .with_subscribe {
      display: flex;
      flex-direction: column;
      gap: 0px;

      .region_title {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000000;
      }

      .subscribe {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #1BA92A;
      }
    }
  }

  .two {
    display: flex;
    align-items: center;
    gap: 24px;

    .amount {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.01em;
    }

    .blue {
      color: #2585C7;
    }

    .red {
      color: #FF4141;
    }

    .green {
      color: #1BA92A;
    }
  }

  .three {
    display: flex;
    justify-content: flex-end;

    p {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 4px;

      span {
        margin: 0px;
        padding: 0px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #000000;
      }
    }
  }
}