.chart_section {
  width: 100%;
  height: auto;
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  &__header {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include mobile_400 {
      gap: 10px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    @include mobile_330 {
      gap: 10px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    // header left
    &__left {
      display: flex;
      align-items: flex-start;
      gap: 8px;

      h5 {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: #000000;
      }
    }
    // header right
    &__right {
      display: flex;
      align-items: center;

      p {
        width: 61px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: #606566;
      }

      button {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #eeeeee;
        outline: none;
        background: transparent;

        &:first-child {
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        &:disabled {
          svg {
            opacity: 0.2;
          }
        }
      }
    }
  }

  &__chartbar {
    margin-bottom: 30px;
    position: relative;
  }
}
