.mission {
  &__title {
    margin-top: 32px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 110%;
    color: #ffffff;
    text-align: left;
    max-width: 1004px;
    margin: 32px auto;
    display: block;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &__description {
    width: 90%;
    max-width: 1004px;
    margin: 32px auto;
    min-height: 70vh;
    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
    border-radius: 8px;
    padding: 60px;
    word-wrap: break-word;

    p {
      margin-bottom: 32px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: #000;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

.mission_cover {
  position: absolute !important;
  top: 120px;
  width: 100%;
  height: 400px;
  object-fit: cover;
  z-index: -1;
  @include mobile_500 {
    top: 70px;
  }
}

.top_cover {
  margin-top: 20vh;
  z-index: 5;
}

.new__mission {
  height: auto;

  .cov {
    position: relative;
    height: 400px;
  }

  &__cover {
    width: 100%;
    height: 400px;
    object-fit: cover;
    z-index: -1;
    position: absolute;
    top: 0;
  }

  &__description {
    max-width: 1004px;
    width: 90%;
    margin: 0 auto;
    margin-top: -30vh;
    z-index: 1;

    h1 {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 56px;
      line-height: 110%;
      color: #ffffff;
      margin-bottom: 32px;
    }

    &__body {
      background: #ffffff !important;
      min-height: 60vh;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
      border-radius: 8px;
      padding: 60px;
      word-wrap: break-word;

      p {
        text-align: justify;
        margin-bottom: 32px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: #000;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.mission_mobile {
  position: relative;

  &__desc {
    margin-top: 24px;
    word-wrap: break-word;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
  }
  &__cover {
    position: relative;
    height: 200px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &__title {
      max-width: 228px;
      position: absolute;
      bottom: 30px;
      left: 5%;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 110%;
      color: #ffffff;
    }
  }
}
