// @import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// @import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    // -webkit-tap-highlight-color: transparent;
    // user-select: none;
}

html {
    font-size: 13px;
    overflow-x: hidden;
    @include mobile {
        font-size: 11px;
    }
}

body {
    font-family: "Roboto", sans-serif;
    overflow-x: hidden !important;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
    color: unset;
}

// a:hover {
//     color: $main-color;
// }

img {
    max-width: 100%;
}

.lazy_loading{
  display:flex;
  justify-content:center;
  align-items:center;
  height:100vh
}

.trigger__button{
  border:none;
  outline:none;
  background:transparent;
  display:flex;
  align-items:flex-end
}

.container{
  width: 92%;
  max-width: 1320px;
  margin:0 auto;
  font-family: 'HelveticaNowDisplay';
  font-weight: 400;

  .save_button{
    padding: 12px 24px;
    background: #2697DC;
    outline: none;
    border:none;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    border-radius: 8px;
    cursor: pointer;
    &:disabled{
      opacity: 0.5;
    }
  }
  @include desktop_1400 {
    max-width: 1300px;
    }

  @include desktop_1380 {
    max-width: 1200px;
    }
  @include desktop_1290 {
    max-width: 1100px;
    }
  @include desktop_1190 {
    max-width: 1024px;
    }
  @include desktop_1090 {
    max-width: 980px;
    }
  @include tablet_1024 {
    max-width: 900px;
    }
  @include tablet_900 {
    max-width: 800px;
    }
  @include tablet_830 {
    max-width: 730px;
    }
  @include tablet_768 {
    max-width: 670px;
    }
  @include tablet_700 {
    max-width: 90%;
    }
}



.underline{
    width: 100%;
    border: 1px solid #D7ECF6;
}

.alert_group{
  display: grid;
  flex-direction: column;
  justify-content: flex-start;
  gap:2px;
}

.alert_form{
  width: auto;
  min-width: 200px;
  margin-bottom: 4px;
  background-color: #FF4141;
  color:#fff;
  border-radius:6px;
  padding: 5px ;
}

.error_alert_donate{
  margin-top: 4px;
  color:#FF4141;
  font-family: $fontFamily;
  font-size: 14px;
}


