.swiper {
  width: 100%;
  height: calc(100vh - 121px);
  @include tablet_1024 {
    height: calc(100vh - 84px);
  }
  @include mobile_500 {
    height: calc(100vh - 100px);
  }
}

.swiper-slide {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position:top !important;
  background: white;
  @include mobile_500{
    background-size:100% cover !important;
    background-position:center;
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mySwiper{
  .swiper-slide{
    h1{
      transform: translateY(-190px);
      visibility: hidden;
      color:transparent;
      transition: transform 1.5s ease-in-out ,color 2s ease-in-out,visibility 18s ease-in-out;
    }
  }
  .swiper-slide-active{
      h1{
        transform: translateY(0px);
        visibility: visible;
        color:#fff;
      }
  }
  .swiper-pagination-horizontal{
    max-width: 1320px;
    width:92%;
    margin:0 auto;
    position: absolute;
    right:0;
    left: 0;
    bottom:60.43px !important;
    display: flex;
    align-items: center !important;
    @include desktop_1400 {
      max-width: 1300px;
    }
    @include desktop_1380 {
      max-width: 1200px;
    }
    @include desktop_1290 {
      max-width: 1100px;
    }
    @include desktop_1190 {
      max-width: 1024px;
    }
    @include desktop_1090 {
      max-width: 980px;
    }
    @include tablet_1024 {
      max-width: 900px;
    }
    @include tablet_900 {
      max-width: 800px;
    }
    @include tablet_830 {
      max-width: 730px;
    }
    @include tablet_768 {
      max-width: 670px;
    }
    @include tablet_700 {
      max-width: 90%;
    }
}

  .swiper-pagination-bullet-active{
    transform: scale(1.35);
    background-color:#2495CA !important;
    opacity: 1 !important;
  }
  .swiper-pagination-bullet{
    background-color:#fff;
    opacity: 0.75;
    width: 12px;
    height:12px;
  }
}


.hero__content{
  position: relative !important;
  width: 100%;
  &__bottom{
    position: absolute;
    bottom:60px;
    width: 100%;
    @include tablet_830 {
      bottom:30px;
    }
    &__description{
        display: -webkit-box;
        overflow: hidden;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        padding-top:2px ;
        padding-bottom: 2px;
        width: 50%;
        max-width: 607px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight:400 ;
        font-size: 48px;
        line-height: 52px;
        letter-spacing: -0.01em;
        color:white;
        margin-left: 0px;
        @include tablet_1024 {
          width: 100%;
          margin-bottom: 12px;
        }
        @include mobile_500{
          font-size: 32px;
          line-height: 40px;
        }
    }
  }
}

.hero_content__more{
  display: flex;
  justify-content: flex-end;
  @include tablet_1024 {
    justify-content: flex-start;
  }

  .more_button{
    background:white;
    padding: 12px 24px;
    display: flex;
    align-items:center;
    gap:8px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-radius: 8px;

    @include tablet_1024 {
      margin-bottom: 60px;
    }
  }


}
