.donate_page {
  padding-top: 56px;

  .donate__header {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    letter-spacing: -0.025em;
    color: #2697dc;
  }

  .donate__under {
    font-family: $fontFamily;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    letter-spacing: -0.025em;
    color: #a9a9a9;
    margin-top: 4rem;

  }

  .donate_form {
    .step__one {
      margin-top: 18px;
      padding-bottom: 32px;
      border-bottom: 1px dashed #d2d1d1;

      &__title {
        max-width: 760px;
        width: 82%;
        margin: 0 auto;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28.8px;
        color: #202020;

        span {
          font-weight: 700;
          font-size: 24px;
          line-height: 38.4px;
          color: #2697dc;
          margin-right: 4px;
        }
      }

      .donate__region {
        margin: 0 auto;
        margin-top: 32px;
        max-width: 1014px;
        // min-width: 60%;
        width: 90%;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        flex-wrap: wrap;

        &:last-child {
          margin-top: 10px;
        }

        div {
          display: grid;
        }

        input[type="radio"] {
          display: none;
        }

        label {
          cursor: pointer;
        }

        input[type="radio"]:checked + label {
          background: #2697dc;
          color: #ffffff;
        }

        input[type="radio"] + label {
          background: #f8f8f8;
          border-radius: 8px;
          padding: 20px 26px;
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #202020;
        }
      }
    }

    .step__two {
      margin-top: 32px;

      &__title {
        max-width: 760px;
        width: 82%;
        margin: 0 auto;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 28.8px;
        color: #202020;

        span {
          font-weight: 700;
          font-size: 24px;
          line-height: 38.4px;
          color: #2697dc;
          margin-right: 4px;
        }
      }

      .subscribe {
        max-width: 1014px;
        width: 90%;
        margin: 40px auto;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 39px;

        @include tablet_600 {
          grid-template-columns: repeat(1, 1fr);
        }

        @include tablet_768 {
          gap: 14px;
        }

        label {
          cursor: pointer;
          padding: 32px;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #ffffff;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
          border-radius: 10px;

          @include tablet_768 {
            padding: 12px;
          }

          img {
            width: 200px;
            height: 200px;
            object-fit: contain;

            @include tablet_768 {
              width: 100px;
              height: 100px;
            }
          }

          p {
            margin-top: 32px;
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 47px;
            text-align: center;

            @include tablet_900 {
              font-size: 24px;
              line-height: 32px;
            }
          }

          .amount {
            margin-top: 24px;

            span {
              font-family: $fontFamily;
              font-style: normal;
              font-weight: 400;
              font-size: 20px;
              line-height: 30px;
              color: #2697dc;

              @include tablet_900 {
                font-size: 16px;
                line-height: 24px;
              }

              &:nth-child(1) {
                font-weight: 700;
                font-size: 32px;

                @include tablet_900 {
                  font-size: 20px;
                }
              }
            }
          }
        }

        input[type="radio"] {
          display: none;
        }

        input[type="radio"][disabled] + label {
          border: 6px solid transparent;
          opacity: 0.4;
          cursor: no-drop;
        }

        input[type="radio"]:checked + label {
          border: 6px solid #2697dc;
        }

        input[type="radio"] + label {
          border: 6px solid transparent;
        }
      }
    }
  }
}

.custom {
  padding-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #091e42;
    display: flex;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label::before {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMS4zIiB5PSIxLjMiIHdpZHRoPSIyMy40IiBoZWlnaHQ9IjIzLjQiIHJ4PSIyLjYiIHN0cm9rZT0iI0U4RThFOCIgc3Ryb2tlLXdpZHRoPSIyLjYiLz4KPC9zdmc+Cg==);
  }

  input[type="checkbox"]:checked + label::before {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMS4zIiB5PSIxLjMiIHdpZHRoPSIyMy40IiBoZWlnaHQ9IjIzLjQiIHJ4PSIyLjYiIGZpbGw9IiMyNjk3REMiIHN0cm9rZT0iIzI2OTdEQyIgc3Ryb2tlLXdpZHRoPSIyLjYiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04Ljc2MzI4IDExLjY5ODVDOC41OTE5NiAxMS41MjExIDguMzg3MDMgMTEuMzc5NiA4LjE2MDQ2IDExLjI4MjNDNy45MzM4OCAxMS4xODUgNy42OTAxOCAxMS4xMzM3IDcuNDQzNTkgMTEuMTMxNkM3LjE5NyAxMS4xMjk0IDYuOTUyNDUgMTEuMTc2NCA2LjcyNDIyIDExLjI2OThDNi40OTU5OCAxMS4zNjMyIDYuMjg4NjMgMTEuNTAxMSA2LjExNDI2IDExLjY3NTVDNS45Mzk4OSAxMS44NDk4IDUuODAxOTkgMTIuMDU3MiA1LjcwODYxIDEyLjI4NTRDNS42MTUyMyAxMi41MTM3IDUuNTY4MjQgMTIuNzU4MiA1LjU3MDM4IDEzLjAwNDhDNS41NzI1MyAxMy4yNTE0IDUuNjIzNzYgMTMuNDk1MSA1LjcyMTA5IDEzLjcyMTdDNS44MTg0MiAxMy45NDgyIDUuOTU5OSAxNC4xNTMyIDYuMTM3MjggMTQuMzI0NUw5Ljg1MTU2IDE4LjAzODhDMTAuMTk5OCAxOC4zODY5IDEwLjY3MjEgMTguNTgyNSAxMS4xNjQ2IDE4LjU4MjVDMTEuNjU3IDE4LjU4MjUgMTIuMTI5MyAxOC4zODY5IDEyLjQ3NzYgMTguMDM4OEwxOS45MDYxIDEwLjYxMDJDMjAuMDgzNSAxMC40Mzg5IDIwLjIyNSAxMC4yMzQgMjAuMzIyMyAxMC4wMDc0QzIwLjQxOTcgOS43ODA4IDIwLjQ3MDkgOS41MzcxMSAyMC40NzMgOS4yOTA1MkMyMC40NzUyIDkuMDQzOTMgMjAuNDI4MiA4Ljc5OTM4IDIwLjMzNDggOC41NzExNEMyMC4yNDE0IDguMzQyOTEgMjAuMTAzNSA4LjEzNTU1IDE5LjkyOTIgNy45NjExOEMxOS43NTQ4IDcuNzg2ODEgMTkuNTQ3NCA3LjY0ODkxIDE5LjMxOTIgNy41NTU1M0MxOS4wOTEgNy40NjIxNSAxOC44NDY0IDcuNDE1MTYgMTguNTk5OCA3LjQxNzMxQzE4LjM1MzIgNy40MTk0NSAxOC4xMDk1IDcuNDcwNjggMTcuODgzIDcuNTY4MDFDMTcuNjU2NCA3LjY2NTM0IDE3LjQ1MTQgNy44MDY4MyAxNy4yODAxIDcuOTg0MkwxMS4xNjQ2IDE0LjA5OThMOC43NjMyOCAxMS42OTg1WiIgZmlsbD0iI0ZBRkJGQyIvPgo8L3N2Zz4K);
  }
}

.amount__section {
  width: 60%;
  max-width: 424px;
  margin: 48px auto 24px;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding: 24px;

  @include tablet_600 {
    width: 100%;
  }

  &.opacity {
    opacity: 0.6;
  }

  &__title {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 162.5%;
    color: #a9a9a9;
    padding-left: 10px;
  }

  &__input {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #e3e3e3;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    input {
      border: none;
      outline: none;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      display: flex;
      align-items: center;

      color: #000000;

      &:disabled {
        background: transparent;
      }
    }
  }
}

.donate_button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // padding-top: 56px;
  margin-bottom: 40px;
  margin-top: 20px;

  button {
    padding: 12px 87px;
    background: #2697dc;
    border-radius: 8px;
    cursor: pointer;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #ffffff;
    border: none;
    outline: none;
    margin-bottom: 12px;

    &:disabled {
      opacity: 0.8;
      cursor: no-drop;
    }
  }
}

// donate_popup

.donate__popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.89);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .payment__popup {
    max-width: 630px;
    // height: auto;
    width: 70%;
    background: #ffffff;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;

    @include mobile_500 {
      width: 100%;
      max-width: 100%;
      height: 100vh;
    }

    &__header {
      padding: 22px 16px 22px 24px;
      border-bottom: 1px solid #f6f5f7;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include mobile_500 {
        padding: 16px 16px 16px 24px;
      }

      &__title {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 46px;
        letter-spacing: -0.025em;
        color: #1e1e1e;

        @include mobile_500 {
          font-size: 18px;
          line-height: 26px;
        }
      }

      &__close {
        cursor: pointer;
        border: none;
        background: none;
        outline: none;
      }
    }

    &__body {
      padding: 24px;

      .payment__saved__card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;

        @include mobile_500 {
          grid-template-columns: repeat(1, 1fr);
          gap: 14px;
        }

        &__radios {
          width: 100%;
          display: grid;

          label {
            width: 100%;
            padding: 24px;
            border-radius: 6px;
            -webkit-border-radius: 6px;
            -moz-border-radius: 6px;
            -ms-border-radius: 6px;
            -o-border-radius: 6px;
            cursor: pointer;

            @include mobile_500 {
              padding: 16px;
            }

            img {
              width: 43px;
              object-fit: fill;

              p {
                margin-bottom: 6px;
                font-family: $fontFamily;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #1e1e1e;

                @include mobile_500 {
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }
          }

          input[type="radio"] {
            display: none;
          }

          input[type="radio"] + label {
            border: 1px solid #d7ecf6;
          }

          input[type="radio"]:checked + label {
            border: 2px solid #2697dc;
          }
        }
      }

      .payment__popup__add-card {
        margin-top: 24px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;

        @include mobile_500 {
          grid-template-columns: repeat(1, 1fr);
          gap: 16px;
        }

        &__button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 6px;
          width: 100%;
          padding: 40px;
          background-color: #f6fcff;
          border: none;
          outline: none;
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #2697dc;
          border-radius: 6px;
          cursor: pointer;

          @include mobile_500 {
            padding: 16px;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }

  &__content {
    max-width: 630px;
    width: 70%;
    background: #ffffff;
    border-radius: 6px;

    @include mobile_500 {
      width: 100%;
      height: 100%;
    }

    &__head {
      padding: 22px 16px 22px 24px;
      border-bottom: 1px solid #f6f5f7;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__title {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 35px;
        letter-spacing: -0.025em;
        color: #1e1e1e;

        @include mobile_500 {
          font-size: 18px;
          line-height: 30px;
        }
      }

      &__close {
        cursor: pointer;
        border: none;
        background: none;
        outline: none;
      }
    }

    &__body {
      padding: 32px 24px 24px 24px;

      @include mobile_500 {
        padding-top: 10vh;
      }

      p {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;

        span {
          color: #2697dc;
        }
      }

      &__checkboxs {
        margin-top: 32px;
        display: flex;
        gap: 32px;

        @include mobile_500 {
          flex-direction: column;
          gap: 12px;
          justify-content: flex-start;
          align-items: flex-start;
        }

        label {
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
          display: flex;
          justify-content: center;
          gap: 8px;
          cursor: pointer;
        }

        input[type="checkbox"] {
          display: none;
        }

        input[type="checkbox"] + label::before {
          content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMSIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiByeD0iMiIgc3Ryb2tlPSIjRThFOEU4IiBzdHJva2Utd2lkdGg9IjIiLz4KPC9zdmc+Cg==);
        }

        input[type="checkbox"]:checked + label::before {
          content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMSIgeT0iMSIgd2lkdGg9IjE4IiBoZWlnaHQ9IjE4IiByeD0iMiIgZmlsbD0iIzI2OTdEQyIgc3Ryb2tlPSIjMjY5N0RDIiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02Ljc0MTI4IDguOTk4ODRDNi42MDk1IDguODYyMzkgNi40NTE4NyA4Ljc1MzU2IDYuMjc3NTcgOC42Nzg2OUM2LjEwMzI4IDguNjAzODIgNS45MTU4MyA4LjU2NDQxIDUuNzI2MTQgOC41NjI3NkM1LjUzNjQ2IDguNTYxMTEgNS4zNDgzNCA4LjU5NzI2IDUuMTcyNzggOC42NjkwOUM0Ljk5NzIxIDguNzQwOTIgNC44Mzc3MSA4Ljg0NyA0LjcwMzU4IDguOTgxMTNDNC41Njk0NCA5LjExNTI2IDQuNDYzMzcgOS4yNzQ3NiA0LjM5MTU0IDkuNDUwMzNDNC4zMTk3MSA5LjYyNTkgNC4yODM1NiA5LjgxNDAxIDQuMjg1MjEgMTAuMDAzN0M0LjI4Njg2IDEwLjE5MzQgNC4zMjYyNyAxMC4zODA4IDQuNDAxMTQgMTAuNTU1MUM0LjQ3NjAxIDEwLjcyOTQgNC41ODQ4NCAxMC44ODcxIDQuNzIxMjggMTEuMDE4OEw3LjU3ODQzIDEzLjg3NkM3Ljg0NjMyIDE0LjE0MzggOC4yMDk2MiAxNC4yOTQyIDguNTg4NDIgMTQuMjk0MkM4Ljk2NzIzIDE0LjI5NDIgOS4zMzA1MyAxNC4xNDM4IDkuNTk4NDIgMTMuODc2TDE1LjMxMjcgOC4xNjE2OUMxNS40NDkyIDguMDI5OTEgMTUuNTU4IDcuODcyMjggMTUuNjMyOSA3LjY5Nzk5QzE1LjcwNzcgNy41MjM2OSAxNS43NDcxIDcuMzM2MjQgMTUuNzQ4OCA3LjE0NjU1QzE1Ljc1MDQgNi45NTY4NyAxNS43MTQzIDYuNzY4NzUgMTUuNjQyNSA2LjU5MzE5QzE1LjU3MDYgNi40MTc2MiAxNS40NjQ1IDYuMjU4MTIgMTUuMzMwNCA2LjEyMzk5QzE1LjE5NjMgNS45ODk4NSAxNS4wMzY4IDUuODgzNzggMTQuODYxMiA1LjgxMTk1QzE0LjY4NTYgNS43NDAxMiAxNC40OTc1IDUuNzAzOTcgMTQuMzA3OSA1LjcwNTYyQzE0LjExODIgNS43MDcyNyAxMy45MzA3IDUuNzQ2NjggMTMuNzU2NCA1LjgyMTU1QzEzLjU4MjEgNS44OTY0MiAxMy40MjQ1IDYuMDA1MjUgMTMuMjkyNyA2LjE0MTY5TDguNTg4NDIgMTAuODQ2TDYuNzQxMjggOC45OTg4NFoiIGZpbGw9IiNGQUZCRkMiLz4KPC9zdmc+Cg==);
        }
      }

      &__buttons {
        margin-top: 32px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 30px;

        @include mobile_500 {
          gap: 16px;
          padding-top: 15vh;
        }

        button {
          border: none;
          outline: none;
          background: none;
          cursor: pointer;

          &:disabled {
            opacity: 0.7;
          }

          &:nth-child(1) {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #2697dc;

            @include mobile_500 {
              font-size: 12px;
              line-height: 20px;
            }
          }

          &:nth-child(2) {
            padding: 12px 24px;
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            display: flex;
            align-items: center;
            letter-spacing: 0.02em;
            color: #ffffff;
            background: #2697dc;
            border-radius: 8px;

            @include mobile_500 {
              padding: 10px 20px;
              font-size: 10px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}

.excute__popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.89);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .message__popup {
    padding: 24px;
    max-width: 350px;
    width: 50%;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include mobile_500 {
      max-width: 100%;
      width: 80%;
    }

    h4 {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 35px;
      letter-spacing: -0.025em;
      color: #1e1e1e;
      margin-top: 16px;

      @include mobile_500 {
        font-size: 18px;
        line-height: 28px;
      }
    }

    p {
      margin-top: 6px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.01em;
      color: #000000;

      @include mobile_500 {
        font-size: 14px;
        line-height: 22px;
      }
    }

    button {
      margin-top: 24px;
      padding: 12px 24px;
      border: none;
      outline: none;
      background: #2697dc;
      border-radius: 8px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #ffffff;
      cursor: pointer;

      @include mobile_500 {
        font-size: 12px;
        line-height: 20px;
        padding: 6px 20px;
      }
    }
  }
}

.loader_donate {
  width: 100%;
  display: flex;
  justify-content: center;
}

.donate__fix__button {
  position: fixed;
  bottom: 1.5rem;
  width: 20%;
  left: 80%;
  right: 2rem;
  align-content: center;
  background-color: #2697dc;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);

  border-radius: 1.5rem;
  color: white;
  font-weight: 700;
  font-size: 1.5rem;

  height: 3rem;

  text-align: center;
}
