.faq {
  padding-top: 56px;

  &__header {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    letter-spacing: -0.025em;
    color: #2697dc;
  }
}
