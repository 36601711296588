.term_privacy{
  &__title{
    padding-top: 56px;
    padding-bottom: 62px;
    font-family:$fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    letter-spacing: -0.025em;
    color: #1E1E1E;
    @include mobile_500{
      font-size: 24px;
      line-height: 120%;
      padding-bottom: 20px;
    }
  }

  &__desc{
    padding-top: 32px;
    padding-bottom: 60px;
    width: 92%;
    max-width: 1273px;
    margin:0 auto;


    p{
      margin-bottom: 12px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 162.5%;
      letter-spacing: -0.03em;
      color: #000000;
    }
  }
}