.second-slider{

  min-height: 55vh;
  margin-top: 60px;
  background-image: linear-gradient(180deg, #FFFFFF 8.08%, rgba(255, 255, 255, 0) 49.93%, #FFFFFF 92.66%), url('../../images/second_slider.png') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center 100% !important;
  @include tablet_1024 {
    height: 428px;
    margin-top: 0px;
  }
  @include mobile_500 {
    min-height: 428px;
    height: auto;
    max-height: 500px;
    margin-top: 0px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items:center;
    background:transparent;
  }

  .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom:-15vh;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap:4px;
    width: 100%;
  }

  .swiper-pagination-bullet-active{
    transform: scale(1.35) !important;
    background:#2495CA;
  }
  .swiper-pagination-bullet{
    width: 12px;
    height:12px;
  }

  .swiper-slide-active{
    .contain{
      transform: translateY(0px);
    }
    .contain__title{
      color:#000;
    }
    .contain__description{
      color:#2495CA;
    }
  }

  .contain{
    width: 100%;
    display: grid;
    gap:8px;
    transform: translateY(90px);
    transition:  transform 1.5s ease-in-out;

    &__title{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 52px;
      text-align: center;
      letter-spacing: -0.01em;
      color:transparent;
      transition: color 2.5s ease-in-out;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
      // -webkit-line-clamp: 3;
      padding-top: 2px;
      padding-bottom: 2px;
      @include tablet_1024 {
        font-size: 32px;
        line-height: 40px;
      }
    }

    &__description{
      font-family: $fontFamily;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 56px;
      letter-spacing: -0.01em;
      color:transparent;
      transition: color 2.5s ease-in-out;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
      // -webkit-line-clamp: 1;
      @include mobile_500{
        line-height: 30px;
      }
    }
  }
}