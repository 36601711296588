.info{
  padding-top: 35px;
  padding-bottom: 35px;
  &__background{
    width: 100%;
    height:50vh;
    background-size: cover;
    display: flex;
    gap:56px;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    border-radius:8px;
    @include tablet_768 {
      height: 444px;
    }
    @include mobile_500 {
      height: 240px;
      padding: 5px;
      gap:16px;
    }
  }
  &__title{
    max-width: 536px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 56px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.01em;
    color:#fff;
    @include tablet_650 {
    font-size: 40px;
    line-height: 42px;
    }
    @include mobile_500 {
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
    }
    span{
      font-weight: 500;
    }
  }
  &__donate-btn{
    padding: 12px 24px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.02em;
    background:#fff;
    color:#2697DC;
    border-radius: 8px;
    transition: all 0.2s ease-in;

    &:hover{
      background:#DBF3FF;
    }
  }
}