.section__news__single{
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
  width: 100%;
  min-height: 312px;
  max-height: 312px;
  border-radius: 8px;
  position: relative;

  @include desktop_1090 {
    min-height: 300px;
    max-height: 300px;
  }

  @include tablet_1024 {
    min-height: 312px;
    max-height: 312px;
  }
  @include tablet_768 {
    min-height: 312px;
    max-height: 312px;
  }
  @include tablet_600 {
    min-height: 300px;
    max-height: 300px;
  }
  @include mobile_500 {
    min-height: 312px;
    max-height: 312px;
  }
  .description{
    padding: 24px;
    position: absolute;
    bottom: 0;
    display: flex;
    z-index: 1;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    .date{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 110%;
      color:white;
      margin-bottom: 8px;
    }
    .news-title{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 110%;
      color:white;
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      white-space: normal;
      -webkit-line-clamp: 4;
      word-break: break-word;
    }

  }
}

.news_hover{
  position: relative;
  z-index: 2;

  rect{
    transition: all 0.6s linear;
  }
  path{
    transition: all 0.4s linear;
  }
  &:hover &__layer{
      background:#2697DC;
      opacity: 0.6;
  }
  &:hover rect{
    fill:white;
  }

  &:hover path{
    stroke:#2697DC;
  }

  &__layer{
    width:100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    border-radius: 8px;
    background: transparent;
    z-index: 1;
    transition: all 0.5s linear;
  }
}

.more_section{
  margin-top: 28px;
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;
  margin-right: 0px;
  .more_btn{
    padding: 12px 24px;
    background:#EDF9FF;
    border-radius:8px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color:#2697DC;
    @include tablet_1024 {
      width: 100%;
      text-align: center;
    }
  }
}