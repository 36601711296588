.news__section{
  margin-top: 60px;

  &__header{
    text-align: center;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    color:#000;
    margin-bottom: 41px;
  }

  &__list{
    display: grid;
    gap:24px;
    grid-template-columns:repeat(4, 1fr);
    @include tablet_1024 {
      grid-template-columns:repeat(2,1fr);
    }
    @include mobile_500 {
      grid-template-columns:repeat(1,1fr);
    }
  }
}


.news__body{
  margin-top: 45px;
  margin-bottom: 25px;
  
  @include mobile_500{
    width: 110%;
  }

  .news_swiper{
    height:auto;
  }
}