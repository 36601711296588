.detail {
  position: relative;
  // height:auto;
  &__date {
    color: #fff;
    font-size: 14px;
    i {
      padding-right: 8px;
    }
  }
  &__cover {
    position: relative;
    height: 400px;
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    z-index: -1;
    @include mobile_500 {
      height: 200px;
    }

    &__par {
      position: absolute;
      bottom: 20px;
      width: 95%;
      max-width: 1004px;
      margin: 0 auto;
      left: 50%;
      transform: translateX(-50%);
      &__header {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 700;
        font-size: 56px;
        line-height: 110%;
        color: #fff;
        margin-bottom: 32px;
        @include tablet_768 {
          font-size: 40px;
        }
        @include mobile_500 {
          font-size: 24px;
          margin-top: 70px;
        }
      }
    }
  }
  .detail__inner {
    width: 95%;
    max-width: 1004px;
    margin: 20px auto;
    margin-bottom: 0px;
    z-index: 0;
    @include mobile_500 {
      padding-top: 60px;
    }
    &__header {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 56px;
      line-height: 110%;
      color: #fff;
      margin-bottom: 32px;
      @include tablet_768 {
        font-size: 40px;
      }
      @include mobile_500 {
        font-size: 24px;
        margin-top: 70px;
      }
    }
    &__description {
      background: #ffffff;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.24);
      border-radius: 8px;
      padding: 32px 60px;
      min-height: 600px;
      margin-bottom: 70px;
      @include mobile_500 {
        margin-top: 100px;
        box-shadow: none;
        background: none;
        padding: 0px;
        min-height: 100px;
      }
      p {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 32px;
      }
    }
  }
}

.detail_mobile {
  position: relative;
  margin-bottom: 32px;

  &__date {
    color: #000;
    font-size: 14px;
    margin-left: 20px;
    margin-top: 4px;
    // text-align: right;
    i {
      padding-right: 8px;
    }
  }

  &__desc {
    margin-top: 24px;
    margin-bottom: 24px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 32px;
  }

  .detail_mobile__cover {
    position: relative;
    width: 100%;
    height: 200px;

    &__title {
      width: 100%;
      position: absolute;
      bottom: 30px;
      left: 5%;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 110%;
      color: #ffffff;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
