.cabinet{
  padding-top:56px;
  @include mobile_500{
    padding-top: 32px;
  }

  &__title{
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    letter-spacing: -0.025em;
    color: #1E1E1E;
    display: block;
    &::first-letter{
      text-transform: uppercase;
    }

    @include mobile_500{
      font-size:32px;
      line-height: 120%;
    }
  }

  &__links{
    margin-top: 32px;
    display: flex;
    gap:34px;
    border-bottom: 1px solid #E8E8E8;
    @include tablet_700{
      gap:20px;
      flex-wrap: wrap;
    }
    @include mobile_500{
      display: none;
    }
    .link{
      padding-bottom: 16px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 29px;
      color: #1E1E1E;
      opacity: 0.8;
      @include tablet_768{
        font-size: 18px;
      }
    }
    .active-link{
      font-weight: bold;
      opacity: 1;
      border-bottom: 2px solid #000000;
    }
  }
}


.mobile_cabinet{
  display: none;
  @include mobile_500{
    display: block;
    position: relative;
    padding-bottom: 24px;
    border-bottom:1px solid #D7ECF6;;
    button{
      margin-top: 24px;
      width: 100%;
      background-color: transparent;
      border: 2px solid #F0F0F0;
      border-radius: 8px;
      padding: 12px 16px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #1E1E1E;
      display: flex;
      justify-content: space-between;

      .drop{
        transform: rotate(-180deg);
        transition: transform 0.3s ease;
      }
      .undrop{
        transform: rotate(0deg);
        transition: transform 0.3s ease;
      }
    }
    .mobile_list{
      position: absolute;
      top:55px;
      left:0px;
      width: 100%;
      background-color:#fff;
      border: 2px solid #F0F0F0;
      border-radius: 8px;
      z-index: 10;
      li{
        padding: 8px 16px;
        border-bottom:1px solid rgba($color: #000000, $alpha: 0.1);
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #1E1E1E;
        &:hover{
          background-color:#2697DC;
          color:#F0F0F0;
        }
      }
    }
  }
}