.forget_password {
  min-height: 672px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include mobile_500 {
    min-height: 300px;
  }

  h1 {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    letter-spacing: -0.025em;
    color: #1E1E1E;
    margin-bottom: 16px;

    @include mobile_500 {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .desc {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 162.5%;
    text-align: center;
    letter-spacing: -0.03em;
    display: flex;
    color: #000000;
    gap: 4px;
  }

  .send-again {
    border: none;
    outline: none;
    background-color: transparent;
    margin-top: 24px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2697DC;
    cursor: pointer;

    &:disabled {
      opacity: 0.7;
    }
  }
}

.change_password {
  width: 70%;
  margin: 0 auto;

  @include tablet_900 {
    width: 80%;
  }

  @include tablet_768 {
    width: 100%;
  }

  .group_button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 32px;
    margin-bottom: 100px;

    .forget_btn {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #2697DC;
      border: none;
      outline: none;
      background: transparent;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  h4 {
    margin-top: 71px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 47px;
    color: #1E1E1E;

    @include mobile_500 {
      display: none;
    }
  }

  .input_group-password {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-bottom: 24px;

    @include mobile_500 {
      grid-template-columns: repeat(1, 1fr);
      gap: 0px;
    }


    .password_input {
      display: grid;
      position: relative;

      label {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }

      input {
        margin-top: 4px;
        border: 2px solid #F0F0F0;
        border-radius: 8px;
        outline: none;
        padding: 13px 40px 14px 20px;
      }

      .visible_icon {
        position: absolute;
        top: 40px;
        right: 16px;
      }

      .error_input {
        border: 2px solid #FF0000;
        border-radius: 8px;
      }

      .error {
        margin-top: 6px;
        display: flex;
        gap: 6px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #ff0000;
      }
    }
  }
}



.update-password {
  margin-top: 94px;
  margin-bottom: 87px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1 {
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    letter-spacing: -0.025em;
    color: #1E1E1E;
  }

  h3 {
    margin-top: 16px;
    max-width: 572px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 162.5%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #000000;
  }

  .update_password_form {
    margin-top: 32px;
    max-width: 424px;
    width: 100%;
  }

  .update_pass {
    display: grid;
    gap: 16px;

    .input_group {
      width: 100%;
      display: grid;
      gap: 4px;

      label {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }

      input {
        border: 1px solid #E4E4E4;
        border-radius: 8px;
        padding: 14px 20px;
      }

      .error_input {
        border: 2px solid #ff0000;
      }
    }

    .button_flex {
      margin-top: 24px;
      display: flex;
      justify-content: flex-end;

      button {
        padding: 12px 24px;
        min-width: 160px;
        background: #2697DC;
        border-radius: 8px;
        outline: none !important;
        border: none !important;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #FFFFFF;

        &:focus {
          border: none;
          border: none;
        }
      }
    }
  }
}