.error_page{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &__title{
    font-family: $fontFamily;
    padding: 56px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    letter-spacing: -0.025em;
    color: #1E1E1E;
  }
  &__button{
    padding: 12px 24px;
    background:#EDF9FF;
    display: flex;
    justify-content: center;
    align-items:center;
    gap:12px;
    border-radius: 8px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #2697DC;
    path{
      stroke:#2697DC;
    }
  }

  .success__email__title{
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    text-align: center;
    letter-spacing: -0.025em;
    color: #1E1E1E;
    margin-bottom: 16px;
    margin-top: 120px;
    @include tablet_650{
      font-size: 24px;
      line-height: 30px;
    }
  }
  .success__email__desc{
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 162.5%;
    text-align: center;
    letter-spacing: -0.03em;
    color: #000000;
    margin-bottom: 32px;
  }
}