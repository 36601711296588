.social__links{
  position:fixed;
  top:50%;
  left:0;
  transform: translateY(-50%);
  width: 64px;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  margin:0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:32px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  z-index: 100;
  @include tablet_650 {
    display: none;
  }
}