.banner {
  backdrop-filter: blur(4px) saturate(60%);
  z-index: 100000000000;
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__mob {
    display: none;
    @include mobile_500 {
      display: block;
    }
  }

  &__web {
    @include mobile_500 {
      display: none;
    }
  }

  &__close {
    cursor: pointer;
    border: none;
    outline: none;
    position: absolute;
    display: block;
    right: 30%;
    top: 25%;
    z-index: 100;
    width: 48px;
    height: 48px;
    background: rgb(237, 249, 255);
    border-radius: 100px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    @media only screen and (max-width: 1600px) {
      right: 26%;
    }
    @media only screen and (max-width: 1500px) {
      right: 25%;
    }
    @media only screen and (max-width: 1400px) {
      right: 24%;
    }
    @media only screen and (max-width: 1300px) {
      right: 20%;
    }
    @media only screen and (max-width: 1000px) {
      right: 10%;
    }
    @include tablet_768 {
      right: 2%;
    }
    @include tablet_720 {
      top: 27%;
    }
    @include tablet_650 {
      top: 29%;
    }
    @include tablet_600 {
      top: 32%;
    }
    @include mobile_500 {
      top: 13%;
      right: 11%;
    }
    @media only screen and (max-width: 450px) {
      right: 10%;
      top: 16%;
    }
    @include mobile_400 {
      top: 20%;
    }
    @include mobile_330 {
      top: 26%;
    }
  }

  img {
    width: 800px;
    height: auto;
    cursor: pointer;
    @include mobile_500 {
      width: 80%;
    }
  }
}
