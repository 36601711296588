@import "./fonts";
@import "./variable";
@import "./mixin";
@import "./base";

@import "./components/header";
@import "./components/footer";
@import "./components/submenu";
@import "./components/swiper";
@import "./components/navigation-head";
@import "./components/sociallinks";
@import "./components/sidebar-option";
@import "./components/second-slider";
@import "./components/news-section";
@import "./components/single-news";
@import "./components/info_background";
@import "./components/partners";
@import "./components/regions";
@import "./components/detail-inner";
@import "./components/media";
@import "./components/all-news";
@import "./components/publication";
@import "./components/pagination";
@import "./components/mission";
@import "./components/cancel_survey";
@import "./components/structure";
@import "./components/reports";
@import "./components/donate-progress";
@import "./components/chart-section";
@import "./components/error-page";
@import "./components/term";
@import "./components/donate";
@import "./components/skeleton";
@import "./components/mobile_menu";
@import "./components/mobileSidebar";
@import "./components/search_news";
@import "./components/soon";
@import "./components/banner";
@import "./components/certificate";
@import "./components/faq";
// auth
@import "./components/auth/auth";

// cabinet
@import "./components/cabinet/cabinet";
@import "./components/cabinet/user-profile";
@import "./components/cabinet/change_password";
@import "./components/cabinet/user_reports";
@import "./components/cabinet/my-donation";
@import "./components/cabinet/payment_methods";
@import "./components/cabinet/donate-detail";

.tooltip {
  position: relative;
  background-color: #44494b;
  padding: 5px 10px;
  border-radius: 8px;
  color: #fff;
  z-index: 10000 !important;
}
.tooltip::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  width: 15px;
  height: 15px;
  background: #44494b;
  transform: translate(-50%) rotate(45deg);
  z-index: -1;
}
