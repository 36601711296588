.partners{
  padding-bottom: 30px;
  &__header{
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color:#000;
    text-align: center;
    margin-bottom: 36px;
  }

  &__slider{
    max-width: 696px;
    height:240px;
    margin-bottom: 10px;
    display: flex;
    align-items:flex-start;
    @include mobile_500{
      height: 200px;
    }

    .swiper-slide {
      height: 100%;
      transition:all 200ms linear;
      transform: scale(0.8);
      padding-bottom: 10px;
      // background-color:#000;
      display: flex;
      align-items:flex-start;
      justify-content: center;
      border-radius: 8px;
      opacity: 0.5;
      transition: all 0.4s ease;

      @include mobile_500{
        height: 200px;
      }
      img{
        width:100%;
        height: 136px;
        object-fit: contain;
        cursor: pointer;
        transition: all 0.7s ease;

        @include mobile_500{
          width: 45px;
          height: 100px;
          object-fit: contain;
        }
        // object-fit: fill;
      }
      &.swiper-slide-active{
        padding-bottom: 0px;
        transform: scale(1);
        align-items:flex-start;
        margin-top: 20px;
        padding: 0px !important;
        opacity: 1;
        // background-color: red;
        @include mobile_500{
          padding: 10px;
        }
        img{
          width: 100%;
          height: 205px;

          @include mobile_500{
            width: 100px;
          }
        }
      }
    }
  }

  &__change-btn{
    display: flex;
    justify-content: center;
    align-items:center;
    gap:6px;

    svg{
      cursor: pointer;
    }
  }

}

