footer {
  background-color: $primary-bg-light;
  width: 100%;
  min-height: 300px;
  @include mobile_500 {
    padding-bottom: 30px;
  }
}

.footer {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;
  @include mobile_500 {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  // footer left
  &__left {
    width: 50%;
    @include tablet_768 {
      width: 45%;
    }
    @include mobile_500 {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .footer_email {
      @include mobile_500 {
        width: 90%;
      }
    }
    // logo
    &__logo {
      width: 200px;
      height: 86px;
      object-fit: cover;
      cursor: pointer;
    }
    // description
    &__description {
      max-width: 411px;
      margin-top: 17px;
      margin-bottom: 32px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      letter-spacing: 0.01em;
      color: #5a89aa;
      @include tablet_1024 {
        max-width: 335px;
        font-size: 16px;
      }
      @include mobile_500 {
        width: 100%;
        max-width: 100%;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        padding: 0px;
      }
    }

    &__label {
      color: $txt-main-color;
      font-family: $fontFamily;
      font-weight: 500;
      font-size: 24px;
      font-style: normal;
      line-height: 150%;
      letter-spacing: 0.03em;
      @include tablet_900 {
        font-size: 20px;
      }
      @include mobile_500 {
        font-size: 24px;
        display: flex;
        justify-content: center;
      }
    }
  }
  // footer right begin
  &__right {
    width: 40%;
    display: flex;
    justify-content: space-between;
    @include tablet_1024 {
      width: 50%;
    }
    @include tablet_900 {
      margin-top: 20px;
    }
    @include tablet_768 {
      width: 47%;
      flex-direction: column;
    }
    @include mobile_500 {
      width: 100%;
    }
    // first
    &__first {
      width: 38%;
      border-right: 1px solid $main-color;

      @include tablet_768 {
        border: none;
      }

      @include mobile_500 {
        padding-top: 32px;
        width: 70%;
        margin: 0 auto;
      }
      &__links {
        margin: 0;
        padding: 0;
        @include tablet_768 {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
        }
        @include mobile_500 {
          div {
            &:first-child {
              border-right: 1px solid $main-color;
            }
          }

          gap: 30px;
        }

        &__item {
          color: $txt-main-color;
          margin-bottom: 16px;
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 150%;
          letter-spacing: 0.03em;
          @include tablet_900 {
            font-size: 20px;
          }

          @include tablet_768 {
            font-size: 18px;
          }

          &:hover {
            color: #2196f3 !important;
          }
        }
      }
    }
    // second begin
    &__second {
      width: 48%;
      @include mobile_500 {
        padding-top: 32px;
        width: 70%;
        margin: 0 auto;
      }
      h2 {
        margin-bottom: 32px;
        font-family: $fontFamily;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        line-height: 150%;
        letter-spacing: 0.03em;
        color: $txt-main-color;
        @include tablet_768 {
          font-size: 18px;
          margin-bottom: 16px;
        }
        @include mobile_500 {
          text-align: center;
        }
      }
      &__links {
        max-width: 193px;
        @include mobile_500 {
          max-width: 100%;
        }
        &__item {
          margin-bottom: 16px;
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          letter-spacing: -0.01em;
          @include tablet_768 {
            font-size: 16px;
          }
          @include mobile_500 {
            text-align: center;
          }

          a:hover {
            color: inherit !important;
          }
        }
      }

      &__socials {
        margin-top: 15px;
        margin-left: 0px;
        display: flex;
        gap: 32px;
        @include tablet_900 {
          gap: 20px;
        }
        @include mobile_500 {
          justify-content: center;
        }
      }
    }
    // second end
  }
  // footer right end
}

.email_input {
  width: 311px;
  margin-top: 12px;
  padding: 14px 20px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #5a89aa;
  border: none;
  outline: none;
  border-radius: 8px;
  @include tablet_1024 {
    width: 288px;
  }
  @include tablet_900 {
    width: 200px;
  }
  @include tablet_768 {
    width: 100%;
  }

  &::placeholder {
    color: #5a89aa;
  }
}
.error_email {
  border: 1px solid #ff0000;
}
.submit_btn {
  margin-left: 12.5px;
  min-width: 110px;
  padding: 12px 32px;
  font-family: $fontFamily;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #fff;
  background-color: $main-color;
  border: none;
  outline: none;
  border-radius: 8px;
  cursor: pointer;
  &:disabled {
    opacity: 0.7;
  }
  @include tablet_900 {
    padding: 12px 20px;
  }
  @include tablet_900 {
    margin-left: 0px;
    margin-top: 12px;
    width: 100%;
  }
}

.subfooter__yigim {
  width: 100%;
  display: flex;
  padding-bottom: 12px;
  @include mobile_500 {
    justify-content: center;
  }
  img {
    width: 40px;
  }
  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    width: 50%;
    @include mobile_500 {
      width: auto;
    }
    &__nav__items__yigim {
      img {
        width: 80px;
      }
    }
  }
}

.subfooter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 32px;
  @include tablet_768 {
    gap: 20px;
  }
  @include mobile_500 {
    // width: 85%;
    margin: 0 auto;
    align-items: center;
    gap: 16px;
    flex-direction: column-reverse;
  }
  // left
  &__left {
    width: 50%;
    @include mobile_500 {
      width: auto;
    }
    // nav
    &__nav {
      width: 100%;
      display: flex;
      gap: 43px;
      @include tablet_768 {
        gap: 16px;
      }
      @include tablet_768 {
        gap: 10px;
        align-items: center;
      }
      @include tablet_650 {
        flex-direction: column;
        align-items: flex-start;
      }
      @include mobile_500 {
        gap: 16px;
        flex-direction: row;
        justify-content: flex-start;
      }
      // items
      &__items {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: #5a89aa;
        @include tablet_900 {
          font-size: 16px;
        }
        @include tablet_768 {
          font-size: 14px;
        }
        @include mobile_500 {
          width: auto;
          text-align: center !important;
          display: flex;
          justify-content: flex-start;
          // align-items:center;
          flex-wrap: wrap;
          gap: 2px;
        }
        a {
          font-weight: 500;
          color: #2697dc;

          @include mobile_500 {
            margin-top: 0px;
          }
        }
      }
    }
    // nav end
  }
  // left end
  // right begin
  &__right {
    width: 40%;
    display: flex;
    @include tablet_1024 {
      width: 50%;
    }
    @include mobile_500 {
      width: auto;
      margin-left: 10px;
    }
    &__navs {
      width: 100%;
      display: flex;
      gap: 10px;
      justify-content: flex-start;
      @include tablet_650 {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;
      }
      @include mobile_500 {
        flex-direction: row;
        margin-left: 0px;
        justify-content: flex-start;
        gap: 16px;
        text-align: center;
      }

      li {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: $txt-main-color;
        @include tablet_900 {
          font-size: 16px;
        }
        @include tablet_768 {
          font-size: 14px;
        }
        @include tablet_650 {
          width: 100%;
        }
        @include mobile_500 {
          width: auto;
          text-align: center;
        }
      }

      &__first {
        width: 50%;
        @include tablet_900 {
          font-size: 16px;
        }
      }
      &__second {
        width: 40%;
        max-width: 193px;
        @include tablet_900 {
          width: 50%;
        }
      }
    }
  }
  // right end
}

.max_Height {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 3;
}

.error_message {
  color: red;
  padding-top: 10px !important;
  display: flex;
  align-items: center;
  gap: 3px;
  svg {
    width: 18px;
    height: 18px;
  }
}
