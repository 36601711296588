.header_section {
  height: 121px;
  width: 100%;

  @include tablet_1024 {
    height: 84px;
    width: 100% !important;
  }
}

header {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 121px;
  z-index: 100;
  background-color: $main-bg;
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.12));

  @include tablet_1024 {
    height: 84px;
  }
}

.header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  // left
  &__left {
    width: 60%;
    display: flex;
    gap: 40px;

    // logo
    &__logo {
      width: 200px;
      height: 86px;

      @include tablet_1024 {
        width: 119px;
        height: 50.74px;
      }

      &__img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    // nav links
    &__links {
      display: flex;
      align-items: center;
      gap: 24px;

      @include tablet_1024 {
        display: none;
      }

      // item
      &__item {
        font-family: $fontFamily;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $txt-main-color;
        letter-spacing: 0.02em;
        cursor: pointer;

        &:hover {
          color: #2697DC;
        }
      }
    }
  }


  // right
  &__right {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    overflow-x: hidden;

    @include tablet_1024 {
      width: 70%;
    }

    @include tablet_700 {
      width: 80%;
    }

    &__links {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }
  }
}

.donate {
  color: $txt-white;
  background-color: #34a853;
  padding: 16px 24px;
  border-radius: 8px;
  font-family: $fontFamily;
  min-height: 48px;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: $lt_02;
  transition: all 0.3s ease-in-out;

  // @include tablet_1024 {
  //   display: none;
  // }

  &:hover {
    background-color: $main-color;
  }
}

.cabinet_btn {
  display: flex;
  min-height: 48px;
  gap: 6px;
  align-items: center;
  justify-content: center;
  padding: 12px 24px !important;
  font-family: $fontFamily;
  line-height: 24px;
  background-color: $primary-light;
  color: $txt-primary;
  border-radius: 8px;
  margin-left: 16px;

  @include tablet_1024 {
    display: none;
  }
}

.user_cabinet {
  border: none;
  outline: none;
  cursor: pointer;
  gap: 12px;
  margin-left: 16px;
  min-width: 170px;
  padding: 6px;
  display: flex;
  align-items: center;
  background: #EDF9FF;
  border-radius: 8px;

  @include tablet_1024 {
    display: none;
  }

  .first_info {
    min-width: 35px;
    height: 35px;
    display: flex;
    padding: 9px;
    justify-content: flex-start;
    align-items: center;
    background: #2697DC;
    border-radius: 4px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 12.7273px;
    line-height: 130%;
    color: #FFFFFF;
  }

  .second_info {
    display: flex;
    align-items: center;
    gap: 12px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: right;
    letter-spacing: 0.02em;
    color: #2697DC;
  }

  .rotate {
    rotate: -180deg;
    transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
  }
}

.person_img {
  width: 22px;
  height: 22px;
}

.lang-btn {
  border: none;
  outline: none;
  background-color: transparent;
  margin-left: 24px;
  text-transform: uppercase;
  display: flex;
  gap: 7px;
  align-items: center;
  cursor: pointer;

  @include tablet_1024 {
    display: none;
  }
}

.search-icon {
  margin-left: 26px;
  margin-top: 4px;
  width: 19px;
  height: 19px;
  cursor: pointer;
}


.opened {
  height: auto;
}

.icon {
  transition: all 0.5s ease;
}

.icon_active {
  padding-top: 0px;
  transform: rotate(-180deg);

  svg {
    path {
      stroke: #2697DC;
    }
  }
}

.submenus {
  display: flex;
  align-items: center;
  gap: 4px;
}



.all_regions_sub {
  svg {
    transform: rotate(0deg);
    transition: all 0.15s ease-in;
  }

  &:hover .regions_sub_links ul {
    display: block;
    max-height: 200px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(48, 50, 51, 0.3);
    }

    &::-webkit-scrollbar-thumb {

      background-color: rgba(38, 151, 220, 0.7);
      outline: 1px solid slategrey;
    }
  }

  &:hover svg {
    transform: rotate(-180deg);

    path {
      stroke: #2697DC;
    }
  }
}

.head__region {
  display: flex;
  align-items: center;
  gap: 8px;
}

.regions_sub_links {
  position: absolute;
  top: 10;
  left: -10px;
  color: #1E1E1E;

  ul {
    display: none;
    width: 211px;
    margin-top: 10px;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: #FFFFFF;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
    border-radius: 4px;

    li {
      padding: 8px 16px;
      font-family: $fontFamily;

      &:hover {
        background: #EDF9FF;
        color: #2196F3;
      }
    }
  }
}

.search__form.active_form {
  // opacity: 1;
  transform: translate(0, -50%);

  @include mobile_500 {
    position: fixed;
    width: 91.2%;
    margin-right: 15px;
  }

  // z-index: 10;
}

.search__form {
  // opacity: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // height: 48px;
  width: 100%;
  border-radius: 8px;
  position: absolute;
  padding-right: 3px;
  top: 50%;
  transform: translate(100vw, -50%);
  right: 0;
  background: white;
  border: 2px solid #D9D9D9;
  border-radius: 8px;
  transition: transform 0.7s ease-in-out;

  @include tablet_900 {
    width: 330px;
  }

  @include tablet_700 {
    width: 100%;
  }

  &:focus-within {
    border: 2px solid #2697DC
  }

  input {
    width: 100%;
    padding: 12px 20px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
    color: #000000;
    border-radius: 8px;
    border: none;
    outline: none;

    &::-webkit-search-cancel-button {
      position: relative;
      top: 10px;
      right: -30px;
      -webkit-appearance: none;
      background-image: url('../../images/close_input.svg') !important;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      font-size: 24px !important;
      font-weight: normal !important;
      color: red;
    }

    &::placeholder {
      color: #D9D9D9;
    }

  }

  button {
    border: none;
    outline: none;
    padding-right: 10px;
    padding-top: 4px;
    background: transparent;
    cursor: pointer;
  }
}

.dropdown_profile {
  position: fixed;
  width: 211px;
  top: 100px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  padding: 12px 0px;

  ul {
    li {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #1E1E1E;
      padding: 12px 16px;
      cursor: pointer;

      &:hover {
        background: #EDF9FF;
        color: #2196F3;
      }

      &:last-child {
        border-top: 1px solid #D7ECF6;
        color: #FF4141;

        &:hover {
          background-color: #FF414120;
        }
      }
    }
  }
}


.menu_icon {
  display: none;

  @include tablet_1024 {
    display: inline-block;
    margin-left: 24px;
  }
}