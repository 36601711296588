.pdf-certificate {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  &__inner {
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 70%;
    height: 600px;
    @include desktop_1400 {
      width: 80%;
    }
    @include desktop_1090 {
      height: 550px;
    }
    @include desktop_1060 {
      height: 500px;
    }
    @include mobile_500 {
      height: 200px;
    }
  }
}

.certificate {
  width: 80%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 14px solid;
  border-radius: 20px;
  border-color: #2697dd;
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
  font-size: 32px;
  font-family: "HelveticaNowDisplay";

  &__buttons {
    width: 100%;
    margin-top: 50px;

    &__group {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      width: 20%;
      margin-left: auto;
      margin-right: auto;

      button {
        color: $txt-white;
        background-color: #34a853;
        border-color: #34a853;
        padding: 8px 12px;
        border-radius: 8px;
        font-family: $fontFamily;
        min-height: 48px;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: $lt_02;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: $main-color;
        }
      }
    }
  }

  &__images {
    margin-top: 40px;
    margin-bottom: 120px;
    display: flex;
    justify-content: space-between;
    width: 32%;
    &__logo {
      min-height: 220px;
      max-height: 240px;
    }
    img {
      max-height: 200px;
    }
  }

  &__name {
    width: 50%;
    border-bottom: 1px solid;
    margin-top: 60px;
    margin-bottom: 50px;
    p {
      font-size: 46px;
      font-weight: 600;
      word-spacing: 12px;
      letter-spacing: 4px;
      font-family: "HelveticaNowDisplay";
    }
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
  }

  &__last {
    margin-top: 30px;
    margin-bottom: 160px;
    font-weight: 600;
  }
}
