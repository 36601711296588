.news_skeleton{
  display: grid;
  grid-template-columns: repeat(4,1fr);
  gap:24px;
  @include tablet_1024 {
    grid-template-columns:repeat(2,1fr);
  }
  @include mobile_500 {
    grid-template-columns:repeat(1,1fr);
  }
}

.skeloton_image{
  width: 210px;
  height:90px;
  @include tablet_1024 {
    height: 54px !important;
    width: 150px !important;
  }
}

.banner_skeleton{
  width: 100%;
  background:#44494b15;
  height:calc(100vh - 121px);
}

.trustee_skeleton{
  height: 55vh;
  margin-top: 60px;
  background:#44494b10;

  .inner_trustee{
    padding: 10vh 100px;
    padding-top: 20vh;
  }
}

.detail_skeleton{
  .inner_skeleton{
    margin-top: -15vh;
    margin-bottom: 40px;
  }
}


.region_skeleton{
  display: grid;
  grid-template-columns: repeat(5,1fr);
  gap:24px;
  @include tablet_1024{
    grid-template-columns: repeat(4,1fr);
  }
  @include tablet_768{
    grid-template-columns: repeat(2,1fr);
  }
}


.structure_skeleton{
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap:100px;
  align-items: flex-start;

  .first_structure{
    width: 400px;
    margin-top: 30px;

  }

  .inner_structure{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap:24px;
  }
}

.loader{
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
}

.region_loader{
  height: 40vh;
  display:flex;
  justify-content:center;
  align-items:center;
}
.year_loader{
  height: 48vh;
  display:flex;
  justify-content:center;
  align-items:center;
}

.subscribe_loader{
  height: 40vh;
  display:flex;
  justify-content:center;
  width: 100%;
  align-items:center;
}


.user_profile_loader{
  min-height: 40vh;
  display:flex;
  justify-content:center;
  width: 100%;
  align-items:center;
}


.dont_active_subscribe{
  margin-top: 20px;
  margin-bottom: 20px;

  p{
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7C9EAE;
  }
}

.data_loader{
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_loader{
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items:center;
}