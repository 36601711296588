.paginate{
  display: flex;
  justify-content: center;
  align-items:center;
  // gap:1px;
  &__all{
    border:1px solid #eee;
    border-radius:8px;
    display: flex;
    align-items:center;
    justify-content: center;
    &__number{
      padding: 12px 21px;
      border-right: 1px solid #EEEEEE;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #606566;
      &:nth-of-type(1){
        border-left: 1px solid #eee;
        background:#EDF9FF;
      }
    }
    .active__button{
      background:#EDF9FF;
      color:#2697DC;
    }
  }
  button{
    border:none;
    outline:none;
    background:transparent;
    cursor:pointer;
    &:disabled{
      opacity: 0.45;
    }
  }
  .prev{
    padding: 11px 13.5px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    align-items:center;
    justify-content: center;
  }
  .next{
    padding: 11px 13.5px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    align-items:center;
    justify-content: center;
  }
}