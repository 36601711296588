.sidebar__option {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 3;
  display: flex;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  @include mobile_500 {
    display: none;
  }

  &__first {
    width: 60px;
    min-height: 300px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &__icon {
      transform: rotate(0deg);
      transition: transform 0.2s linear;
      &.active {
        transform: rotate(180deg);
      }
    }
  }

  &__first__faq {
    width: 60px;
    min-height: 150px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &__icon {
      transform: rotate(0deg);
      transition: transform 0.2s linear;
      &.active {
        transform: rotate(180deg);
      }
    }
  }

  &__second {
    width: 0px;
    max-width: 396px;
    height: 300px;
    transition: width 0.5s linear;
    display: flex;
    align-items: center;
    &.active {
      width: calc(396px - 60px);
    }
    &__navs {
      width: 100%;
      max-width: 300px;
      max-height: 200px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      &__item {
        display: flex;
        min-width: 300px;
        align-items: center;
        gap: 16px;
        background: #fff;
        border-radius: 8px;
        padding: 10px 12px;
        transform: translateX(340px);
        transition: transform 1s ease;
        cursor: pointer;
        &.active {
          transform: translateX(0px);
        }
        &.disabled {
          opacity: 0.53;
        }
        img {
          width: 44px;
          height: 44px;
        }
        .list {
          &__title {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #000;
          }
          &__description {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #000;
          }
        }
      }
    }
  }

  &__secondf {
    width: 0px;
    max-width: 285px;
    height: 150px;
    transition: width 0.5s linear;
    display: flex;
    align-items: center;
    &.active {
      width: calc(396px - 60px);
    }
    &__navs {
      width: 100%;
      max-width: 265px;
      max-height: 200px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;

      &__item {
        display: flex;
        min-width: 265px;
        align-items: center;
        gap: 16px;
        background: #fff;
        border-radius: 8px;
        padding: 10px 12px;
        transform: translateX(340px);
        transition: transform 1s ease;
        cursor: pointer;
        &.active {
          transform: translateX(0px);
        }
        &.disabled {
          opacity: 0.53;
        }
        img {
          width: 44px;
          height: 44px;
        }
        .list {
          display: flex;
          justify-content: space-around;
          align-items: center;
          i{
            margin-right: 0.5rem;
          }
          &__title {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #000;
            &__num {
              font-family: $fontFamily;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.02em;
              color: #000;
              margin-left: 0.5rem;
            }
          }
          &__description {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #000;
          }
        }
      }
    }
  }
}
