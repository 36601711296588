.donate__detail {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.9);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    max-width: 600px;
    width: 70%;
    background: #FFFFFF;
    border-radius: 6px;

    @include mobile_500 {
      width: 100%;
    }

    // header
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 16px 16px 24px;
      border-bottom: 1px solid #F6F5F7;

      h6 {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 46px;
        letter-spacing: -0.025em;
        color: #1E1E1E;

        @include mobile_500 {
          font-size: 18px;
          line-height: 36px;
        }
      }

      button {
        cursor: pointer;
        outline: none;
        background: transparent;
        border: none
      }
    }

    // body
    &__body {
      padding: 32px 24px;

      p {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
      }

      .button_group {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 32px;

        button {
          cursor: pointer;
          outline: none;
          border: none;
          background: transparent;
          padding: 12px 24px;
          @include mobile_500{
            padding: 8px 16px;
          }

          &:nth-child(1) {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #2697DC;
          }

          &:nth-child(2) {
            min-width: 130px;
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.02em;
            background: #2697DC;
            border-radius: 8px;
            color: #FFFFFF;
          }

        }
      }
    }
  }
}