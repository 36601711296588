.user_reports {
  width: 80%;
  max-width: 872px;
  margin: 0 auto;

  .report_header {
    margin-top: 72px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid #E8E8E8;
    margin-bottom: 32px;

    @include mobile_500 {
      width: 100%;
      margin-top: 32px;
      display: grid;
      grid-template-columns: repeat(1,1fr);
      gap: 16px;
    }

    .button_group {
      padding: 5px;
      background: #F4F4F4;
      border-radius: 6px;

      @include mobile_500 {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }

      button {
        background: transparent;
        border-radius: 4px;
        border: none;
        outline: none;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #202020;
        padding: 4px 40px;
        cursor: pointer;

        @include mobile_500 {
          padding: 4px auto;
          width: 100%;
        }
      }

      .active_button {
        background: #fff;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #2697DC;
      }
    }
  }

  .user_donation {
    margin-top: 53px;

    .my_donation {
      font-family: $fontFamily;
      // text-transform: capitalize;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      display: flex;
      align-items: flex-end;
      color: #1E1E1E;
      padding-bottom: 24px;
      border-bottom: 1px solid #E8E8E8;

      @include tablet_900 {
        font-size: 24px;
      }
    }

    .my_donation_list {
      padding: 24px;
      margin-top: 32px;
      margin-bottom: 32px;
      background: #FFFFFF;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include tablet_768 {
        display: block;
      }

      @include mobile_500 {
        display: grid;
        gap: 10px;
        position: relative;
      }

      .donate_left {
        .donate_left__header {
          display: flex;
          align-items: center;
          gap: 16px;

          @include mobile_500 {
            display: grid;
            gap: 10px;
          }

          h4 {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 104%;
            color: #2697DC;

            @include tablet_900 {
              font-size: 20px;
            }

            @include mobile_500 {
              font-size: 20px;
            }
          }

          h5 {
            font-family: $fontFamily;
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 104%;
            color: #A8D7ED;

            @include tablet_900 {
              font-size: 20px;
            }

            @include mobile_500 {
              font-size: 20px;
            }
          }
        }

        .donate_left__bottom {
          margin-top: 24px;
          display: flex;
          gap: 72px;

          @include tablet_768 {
            gap: 32px;
            justify-content: space-between;
          }

          @include mobile_500 {
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(2, 1fr);
          }

          &__card {
            display: flex !important;
            align-items: center;
            gap: 6px;

            img {
              object-fit: contain;
              width: 40px;
              height: 40px;
            }
          }

          div {
            display: grid;
            gap: 3px;

            span {
              font-family: $fontFamily;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;

              @include tablet_900 {
                font-size: 14px;
              }
            }

            .date-label {
              color: #000 !important;
            }

            .date-time {
              color: #888;
            }
          }
        }
      }

      .donate_right {
        display: flex;
        align-items: center;
        gap: 50px;
        position: relative;

        @include tablet_768 {
          justify-content: flex-end;
          gap: 20px
        }

        @include mobile_500 {
          justify-content: flex-start;
          gap: 20px;
          position: initial;
        }

        .more-icon {
          cursor: pointer;

          @include mobile_500 {
            position: absolute;
            top: 20px;
            right: 20px;
          }
        }

        p {
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 700;
          font-size: 32px;
          line-height: 47px;
          color: #2697DC;

          @include tablet_900 {
            font-size: 28px;
            line-height: 32px;
          }

          @include tablet_768 {
            font-size: 20px;
            line-height: 22px;
          }

          span {
            opacity: 0.8;
            font-weight: 500;
            font-size: 28px;

            @include tablet_768 {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}

.donate_action {
  position: absolute;
  top: 0;
  right: 0;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
  border-radius: 4px;

  @include tablet_900 {
    right: 20px
  }

  @include mobile_500 {
    transform: translateY(-10px);
  }

  ul {
    padding-top: 4px;
    padding-bottom: 4px;

    li {
      padding: 8px 16px;
      cursor: pointer;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #2697DC;

      @include tablet_900 {
        font-size: 14px;
        line-height: 20px;
      }

      &:hover {
        background: #EDF9FF;
        color: #2196F3;
      }

      &:last-child {
        &:hover {
          color: #FF4141;
          background-color: #FF414120;
        }
      }
    }
  }
}