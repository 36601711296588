.patment_methods{
  width: 80%;
  max-width: 872px;
  margin: 0 auto;

  @include tablet_900{
    width: 100%;
  }
  @include tablet_768{
    width: 80%;
  }

  @include tablet_600{
    width: 100%;
  }

  .headeLine{
    margin-top: 72px;
    padding-bottom: 24px;
    border-bottom: 1px solid #D7ECF6;

    @include tablet_900{
      margin-top: 32px;
    }

    h2{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      display: flex;
      align-items: flex-end;
      color: #1E1E1E;

      @include tablet_900{
        font-size: 28px;
      }
    }
  }

  .all_cards{
    margin: 40px 0px 20px 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap:24px;
    row-gap: 20px;

    @include tablet_768{
      grid-template-columns: repeat(1,1fr);
    }

    .new_cart{
      display: flex;
      align-items:center;
      justify-content: center;
      border: none;
      outline: none;
      height: 103px;
      background: #F6FCFF;
      border-radius: 6px;
      cursor: pointer;
    }

    .cart{
      border-radius: 6px;
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items:center;
    }
    .more_button{
      cursor: pointer;
      .delete{
        border:none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        &:disabled{
          opacity: 0.7;
        }
      }
    }
    .cart_body{
      margin-top: 6px;
    }
    .number{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #1E1E1E;
    }
    .main{
      border: 1px solid #2697DC;
    }
    .main_message{
      margin-left: 8px;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #2697DC;
    }
    .def{
      border: 1px solid #D7ECF6;
    }
  }
}

.not_have_card{
  display: block;
  margin-top: 32px;
  width: 100%;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #1E1E1E;
}

.custom-target-icon{
  svg{
    opacity: 0.5;
  }
}