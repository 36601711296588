.all-news{
  &__header{
    height:171px;
    display: flex;
    justify-content: center;
    align-items:center;
    background:#F8F8F8;
  }

  &__body{
    margin-top: 32px;
    margin-bottom: 32px;
    display: grid;
    gap:24px;
    grid-template-columns:repeat(4, 1fr);
    @include tablet_1024{
      grid-template-columns: repeat(3,1fr);
    }
    @include tablet_830{
      grid-template-columns: repeat(2,1fr);
    }
    @include mobile_500 {
      grid-template-columns: repeat(1,1fr);
    }
  }

  &__filter{
    width: 100%;
    margin-top: 58px;
    display: flex;
    justify-content: flex-end;
    align-items:center;
    position: relative;
    @include mobile_500 {
      display: block;
      margin-top: 32px;
    }

    .title{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 59px;
      text-align: center;
      letter-spacing: -0.025em;
      color: #1E1E1E;
      position: absolute;
      top:0px;
      left: 50%;
      transform: translateX(-50%);
      @include tablet_1024{
        top:10px;
        font-size: 30px;
        line-height: 39px;
      }
      @include tablet_1024{
        left: 0;
        transform: translateX(0);
      }
      @include mobile_500 {
        display: block;
        position: relative;
        margin-bottom: 24px;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
      }
    }

    button{
      align-content: flex-end;
      z-index: 3;
      position: relative;
      width: 300px;
      border:none;
      outline:none;
      border-radius: 8px !important;
      background-color: #fff;
      display: grid;

      @include mobile_500 {
        width: 100%;
      }

      p{
        display: flex;
        justify-content: space-between;
        padding: 12px 24px 12px 16px;
        border: 2px solid #F0F0F0;
        border-radius: 8px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        cursor:pointer;
        img{
          width: 9.05px;
          height:14.1px;
        }
      }
      div{
        display: none;
      }

      div.active{
        display: block;
        position: absolute;
        width: 100%;
        top:40px;
        left:0;
        z-index: 3;
        margin-top: 9px;

        ul{
          padding-top: 6px;
          padding-bottom: 6px;
          background: #FFFFFF;
          margin-top: 10px;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.06);
          border-radius: 4px;
        li{
          text-align: left;
          padding: 8px 16px;
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color:#1E1E1E;
          cursor:pointer;
            &:hover{
              background-color: #EDF9FF;
              color:#2196F3;
            }
            &:first-child{
              margin-top: 6px;
            }

            &:last-child{
              margin-bottom: 6px;
            }
          }
        } 
      }
    }
  }
}

.dropdown_icon{
  transform: rotate(0deg);
  transition: all 0.3s ease-in;
}
.dropdown_icon.active{
  
  transform: rotate(-180deg);
}


.dont_result{
  height: 50vh;
  display: grid;
  justify-content: center;
  align-items: center;

  h4{
    margin-bottom: 30px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    /* identical to box height */
    text-align: center;
    letter-spacing: -0.025em;
    color: #1E1E1E;
    @include mobile_500 {
      font-size: 28px;
      line-height: 32px;
    }
  }
  button{
    cursor: pointer;
    outline: none;
    border:none;
    text-align: center;
    margin: 20px auto;
    display: block;
    padding: 12px 20px;
    background: #2697DC;
    border-radius: 8px;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #FFFFFF;

  }
}

.news_menu{
  max-height: 180px;
  overflow: auto;
}

.news_menu::-webkit-scrollbar {
  width: 4px;
}

.news_menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.news_menu::-webkit-scrollbar-thumb {
background-color: darkgrey;
outline: 1px solid slategrey;
}