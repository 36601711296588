.structure__main{
  margin-top: 32px;
  margin-bottom: 80px;
  display: grid;
  gap:56px;
}

.structure__main__title{
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 59px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #1E1E1E;
  margin-top: 56px;
  @include mobile_500{
    font-size: 24px;
    margin-top: 32px;
  }
}

.structure__main__path{
  display: flex;
  justify-content: space-between;
  align-items:flex-start;

  @include tablet_720{
    flex-direction: column;
    gap:20px;
  }

  &__head{
    width: 30%;
    @include tablet_720{
      width: 100%;
    }

    h2{
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 110%;
      color: #2697DC;

      @include mobile_500{
        font-size: 24px;
        line-height: 120%;
      }
    }
  }

  &__desc{
    width: 65%;
    @include tablet_720{
      width: 100%;
    }
  }
}

.structure__users__all{
  display: grid;
  gap:32px;
  .user__director{
    display: grid;
    gap:24px;
    grid-template-columns:repeat(4, 1fr);
    @include tablet_900{
      grid-template-columns: repeat(3,1fr);
    }
    @include tablet_768{
      grid-template-columns: repeat(2,1fr);
    }
    @include tablet_720{
      grid-template-columns: repeat(3,1fr);
    }
    @include mobile_500{
      grid-template-columns: repeat(2,1fr);
    }
    .link{
      position: relative;
      display: flex;
      flex-direction: column;
      gap:4px;
      align-items:center;

      &:hover .link__more{
        background:#2697DC;
        visibility: visible;

        span{
          color:#fff;
        }
      }
      &__more{
        position: absolute;
        top:0;
        left:50%;
        transform: translateX(-50%);
        border-radius:50%;
        width: 150px;
        height:150px;
        display: flex;
        align-items:center;
        justify-content: center;
        background:transparent;
        visibility: hidden;
        transition: all 0.5s ease-out;
        &:hover span{
          color:#fff;
        }
        span{
          text-align: center;
          font-family: $fontFamily;
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 120%;
          color: transparent;
          transition: all 0.7s ease;
        }
      }
    }
  }

  img{
    width: 150px;
    height:150px;
    object-fit: cover;
    border-radius:50%;
    margin-bottom: 6px;
  }
}

.desc__title{
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 120%;
  color: #2697DC;
  text-align: center;
  display: block;
  @include mobile_500{
    font-size: 16px;
  }
  &::first-letter{
    text-transform: uppercase;
  }
}
.desc__info{
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  color: #959595;
  display: block;
  @include mobile_500{
    font-size: 16px;
  }
  &::first-letter{
    text-transform: uppercase;
  }
}







// detail page
.structure__title{
  padding-top:56px;
  @include tablet_768{
    padding-top: 32px;
  }
  h1{
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 59px;
    text-align: left;
    letter-spacing: -0.025em;
    color: #1E1E1E;
    @include tablet_768{
      display: none;
      margin-top: 0px;
    }
  }
}
.structure__detail{
  margin-top: 32px;
  display: flex;
  gap:24px;
  justify-content: space-between;
  align-items: flex-start;
  @include tablet_768{
    flex-direction: column-reverse;
  }
}

.structure__detail__content{
  max-width: 763px;
  @include desktop_1380{
    max-width: 60%;
  }
  @include desktop_1190{
    max-width: 50%;
  }
  @include tablet_768{
    max-width: 100%;
  }
  @include tablet_600{
    max-width: 100%; 
   }
   h1{
    display: none;
    @include tablet_768{
      margin-top: 12px;
      margin-bottom: 12px;
      display: block;
    }
   }
  p{
    text-align: justify;
    font-family: $fontFamily;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    padding-bottom: 32px;
  }
}

.structure__detail__image{
  width: 40%;
  max-width: 424px;
  @include tablet_768{
    width: 100%;
    margin: 0 auto;
  }

  img{
    width: 100%;
    height: 424px;
    object-fit: cover;
    border-radius:50%;

    @include mobile_500{
      height: 343px;
    }
  }
}